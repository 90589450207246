import { PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EN } from '../../constants';
import { IVendorDetail } from '../../models';
import { Register } from '../../models/commonType';
import { useDispatch, useSelector } from 'react-redux';
import { updateCertificate } from '../../redux/user';
import { imageUpload } from '../../redux/common';
import { ILoaderState } from '../../redux/loader';
import ImageClick from './ImageClick';
import './Profile.scss';

const ServiceDetail: React.FC<{
  register: Register;
  vendorDetail: IVendorDetail;
}> = ({ register, vendorDetail }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onDeleteImage = async () => {
    await dispatch(updateCertificate({ certification: { id: null, url: '' } }));
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const certificate = new FormData();
    certificate.append('url', e.target.files[0]);
    const fileData = await dispatch(imageUpload(certificate));
    await dispatch(
      updateCertificate({
        certification: { id: fileData?.['id'], url: fileData?.['file'] },
      })
    );
  };

  return (
    <>
      <div className="mt-16">
        <PrimaryLabel
          labelText={EN.profile.SERVICE}
          className="sub-header font-brandonMedium text-F20"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-x-8 mt-4">
          <div className="col-span-2 my-bio">
            <PrimaryLabel labelText={EN.profile.Service.BIO} />
            <TextArea
              label=""
              name="vendorBio"
              placeholder=""
              ref={register}
              className="contact-textarea mt-4 mb-4"
            />
          </div>
          <div>
            <PrimaryLabel
              labelText={EN.profile.Service.CERTIFICATE}
              className="block"
            />
            <label htmlFor="files" className="px-simple-button btn-upload">
              {EN.profile.Service.BTN_UPLOAD}
            </label>
            <input
              id="files"
              type="file"
              className="hidden"
              name="Upload"
              onChange={(e) => {
                handleFileUpload(e);
              }}
            />
            {loading && <PrimaryLabel labelText={EN.profile.UPLOADING} />}
            {vendorDetail?.certification?.url && (
              <div className="inline-flex">
                <ImageClick
                  imageUrl={vendorDetail?.certification?.url}
                  onDeleteImage={onDeleteImage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetail;
