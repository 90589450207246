export interface IModalState {
  open: boolean;
  openAssign: boolean;
  openMarkNoShow: boolean;
  openSubRequest: boolean;
  openImgPreview: boolean;
}

export const initialModalState: IModalState = {
  open: false,
  openAssign: false,
  openMarkNoShow: false,
  openSubRequest: false,
  openImgPreview: false,
};
