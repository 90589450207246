import { refreshTokenApi } from './../authentication/authentication.service';
import axios from 'axios';
import Config from '../../config/environment';
import toast from '../toast/toast.service';
import { stopLoading } from './common.action';
import { store } from '../store';
import { refreshToken } from '../authentication';
import jwt_decode from 'jwt-decode';

declare module 'axios' {
  export interface AxiosRequestConfig {
    errorHandler: boolean;
  }
}

export const http = axios.create({
  baseURL: Config.API,
  errorHandler: false,
});

export const authHttp = axios.create({
  baseURL: Config.AUTH_API,
  errorHandler: false,
});

export const httpUpload = axios.create({
  baseURL: Config.API_UPLOAD,
  errorHandler: false,
});

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('errorHandler') && !config['errorHandler']
    ? false
    : true;
};
const errorHandler = (error) => {
  if (isHandlerEnabled(error?.config) && error?.response?.status !== 401) {
    store.dispatch(stopLoading());
    toast.error(error?.response?.data?.['error']?.['message']);
  }
  if (error.response.status === 401) {
    store.dispatch(stopLoading());
    const refresh = store.getState().authentication.refresh;
    if (!refresh) {
      toast.error(error?.response?.data?.['error']?.['message']);
      return error?.response;
    }
    if (refresh && jwt_decode(refresh)?.['exp'] < Date.now() / 1000) {
      localStorage.clear();
      window.location.href = `${Config.URL}/login`;
      return error?.response;
    }
    return refreshTokenApi({
      refresh,
    }).then((data) => {
      store.dispatch(refreshToken(data?.['data']));
      error.config.headers.Authorization = `Bearer ${data?.['data'].access}`;
      return http.request(error.config);
    });
  }
  return error?.response;
};
const successHandler = (response) => {
  return response;
};
http.interceptors.request.use((param) => {
  let auth = {};
  if (store.getState().authentication.access) {
    auth = {
      Authorization: `Bearer ${store.getState().authentication.access}`,
    };
  }
  return {
    ...param,
    headers: {
      ...param.headers,
      ...auth,
    },
  };
});

authHttp.interceptors.request.use((param) => {
  let auth = {};
  if (store.getState().authentication.access) {
    auth = {
      Authorization: `Bearer ${store.getState().authentication.access}`,
    };
  }
  return {
    ...param,
    headers: {
      ...param.headers,
      ...auth,
    },
  };
});

httpUpload.interceptors.request.use((param) => {
  let auth = {};
  if (store.getState().authentication.access) {
    auth = {
      Authorization: `Bearer ${store.getState().authentication.access}`,
    };
  }
  return {
    ...param,
    headers: {
      ...param.headers,
      ...auth,
    },
  };
});

http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
authHttp.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
httpUpload.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
