const development = {
  API: 'https://consumer-api-dev.hom.events',
  URL: 'https://kadbu.com',
  AUTH_API: 'https://dev-auth.hom.events',
  API_UPLOAD: 'https://planner-qa.hom.events',
  VENDOR_URL: 'https://vendor.kadbu.com',
};

const production = {
  API: 'https://consumer-api.helloalfred.com',
  URL: 'https://vendor.helloalfred.com',
  AUTH_API: 'https://auth-api.helloalfred.com',
  API_UPLOAD: 'https://planner-api.helloalfred.com/',
  VENDOR_URL: 'https://vendor.helloalfred.com',
};

const staging = {
  API: 'https://staging-consumer-api.hom.events',
  URL: 'https://hom.events',
  AUTH_API: 'https://staging-auth.hom.events',
  API_UPLOAD: 'https://planner-api.hom.events/',
  VENDOR_URL: 'https://vendor-staging.hom.events',
};

const qaserver = {
  API: 'https://consumer-api.kadbu.com',
  URL: 'https://kadbu.com',
  AUTH_API: 'https://dev-auth.hom.events',
  API_UPLOAD: 'https://planner-api.kadbu.com',
  VENDOR_URL: 'https://vendor.kadbu.com',
};

// eslint-disable-next-line no-undef
const Config = (env = process.env.REACT_APP_ENV) => {
  if (env === 'production') {
    return production;
  } else if (env === 'staging') {
    return staging;
  } else if (env === 'qaserver') {
    return qaserver;
  } else {
    return development;
  }
};
export default Config();
