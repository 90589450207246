import { initialModalState, IModalState } from './modal.state';
import * as modalTypes from './modal.constant';

export const modalReducer = (
  state: IModalState = initialModalState,
  action: { type: string }
): IModalState => {
  switch (action.type) {
    case modalTypes.OPEN_MODAL:
      return {
        ...state,
        open: true,
      };

    case modalTypes.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        openAssign: false,
        openMarkNoShow: false,
        openSubRequest: false,
        openImgPreview: false,
      };
    case modalTypes.OPEN_ASSIGN_MODAL:
      return {
        ...state,
        openAssign: true,
      };
    case modalTypes.OPEN_MARK_NOSHOW:
      return {
        ...state,
        openMarkNoShow: true,
      };
    case modalTypes.OPEN_SUB_REQUEST:
      return {
        ...state,
        openSubRequest: true,
      };
    case modalTypes.OPEN_IMAGE_PREVIEW:
      return {
        ...state,
        openImgPreview: true,
      };
    default:
      return state;
  }
};
