import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EN } from '../../constants';
import { EventOperationInventoryList } from '../../models';

const InventoryList: React.FC<{
  inventory: EventOperationInventoryList[];
}> = ({ inventory }) => {
  return (
    <>
      <PrimaryLabel
        labelText={EN.event.INVENTORY.ItemAssigned}
        className={`text-F18 mb-6 ${
          inventory?.length > 0 ? 'block' : 'hidden'
        }`}
      />

      <div className="grid grid-cols-1 w-auto invlist pl-6 pb-8">
        {inventory?.length > 0 ? (
          inventory.map((item: EventOperationInventoryList, index: number) => {
            return (
              <ul className="w-full pb-6" key={index}>
                <li>
                  <PrimaryLabel
                    labelText={`${item.name} - ${item.itemCount}`}
                    className="block text-F18 text-Color_22 font-brandonMedium"
                  />
                  <PrimaryLabel
                    labelText={`${item.location}`}
                    className="text-F16 text-Color_5"
                  />
                </li>
              </ul>
            );
          })
        ) : (
          <div className="text-center">
            <PrimaryLabel
              labelText={EN.event.INVENTORY.ItemNotAssigned}
              className="text-MobileFont pb-12"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InventoryList;
