import { findIndexFromArrayOfObject } from '../../utils';
import * as eventTypes from './events.constant';
import { IEventState, initialEventState } from './events.state';

export const eventReducer = (
  state: IEventState = initialEventState,
  action: {
    type: string;
    page: number;
    payload: any;
    eventTotal: number;
    eventId: number;
    tenantId?: number;
  }
): IEventState => {
  switch (action.type) {
    case eventTypes.CLEAR_EVENTS:
      return {
        ...state,
        events: [],
        openChat: false,
      };
    case eventTypes.GET_EVENTS:
      return {
        ...state,
        events: [...state.events, ...action.payload],
        eventCount: action.eventTotal,
        openChat: false,
      };
    case eventTypes.GET_EVENT_DATE_COUNT:
      return {
        ...state,
        eventDateCount: action.payload,
      };
    case eventTypes.GET_FILTER_EVENTS:
      return {
        ...state,
        events: [...action.payload],
        eventCount: action.eventTotal,
      };
    case eventTypes.GET_EVENT_DETAIL:
      return {
        ...state,
        eventDetail: action.payload,
        eventDetailFetched: true,
      };
    case eventTypes.GET_VIEW_NOTES_DETAIL:
      return {
        ...state,
        viewNotesDetail: action.payload,
      };
    case eventTypes.ASSIGNED_TO_ME:
      const events = state.events.filter((item) => action.eventId !== item.id);
      return {
        ...state,
        events: [...events],
      };
    case eventTypes.GET_EVENT_OPERATION_DETAIL:
      return {
        ...state,
        eventOperationalDetail: action.payload,
      };
    case eventTypes.MARK_NOSHOW:
      state.eventDetail.markNoShow = action?.['status'];
      return {
        ...state,
        eventDetail: state.eventDetail,
      };
    case eventTypes.MARK_EVENT_START:
      const eventDataArray = state.events.filter(
        (item) => action.eventId === item.id
      );
      const indexNumber = findIndexFromArrayOfObject(
        state.events,
        'id',
        eventDataArray[0]
      );
      state.events[indexNumber].isLiveEvent = action.payload.isLiveEvent;
      return {
        ...state,
        events: [...state.events],
      };
    case eventTypes.OPEN_CHAT_WINDOW:
      return {
        ...state,
        openChat: true,
      };
    case eventTypes.MARK_ATTENDANCE_LOCALY:
      const usersArray = state.eventOperationalDetail.users.filter(
        (item) => action.tenantId === item.tenantId
      );
      const iNumber = findIndexFromArrayOfObject(
        state.eventOperationalDetail.users,
        'tenantId',
        usersArray[0]
      );
      state.eventOperationalDetail.users[iNumber].attended =
        action.payload.attended;
      return {
        ...state,
        eventOperationalDetail: { ...state.eventOperationalDetail },
      };
    case eventTypes.MARK_ATTENDANCE:
      return {
        ...state,
        markUserAttenDance: action.payload,
      };
    case eventTypes.GET_BUILDINGS_LIST:
      return {
        ...state,
        buildingList: action.payload,
      };
    case eventTypes.EMAIL_CHECK:
      return {
        ...state,
        emailExist: action.payload.status,
      };
    case eventTypes.GET_TIMEZONE:
      return {
        ...state,
        timezones: action.payload?.data,
      };
    default:
      return state;
  }
};
