import { Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EN } from '../../constants';
import { Register } from '../../models/commonType';
import './Profile.scss';

const LocationDetail: React.FC<{ register: Register }> = ({ register }) => {
  return (
    <>
      <div className="mt-16">
        <PrimaryLabel
          labelText={EN.profile.LOCATION}
          className="sub-header font-brandonMedium text-F20"
        />
        <div className="grid grid-cols-1 gap-8 md:gap-x-8 mt-4">
          <div>
            <PrimaryLabel labelText={EN.profile.Location.STREET} />
            <Input
              label={EN.profile.Location.STREET}
              name="address"
              ref={register}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationDetail;
