import { IVerifyToken } from './../../models/user';
import { defaultTimezone } from '../../constants';

export interface IUserLoginState {
  access?: string;
  refresh?: string;
  verifyToken?: IVerifyToken;
  userTimezone?: string;
}

export const initialAuthState: IUserLoginState = {
  access: localStorage.getItem('access') ?? null,
  userTimezone: localStorage.getItem('userTimezone')
    ? localStorage.getItem('userTimezone')
    : defaultTimezone,
  refresh: localStorage.getItem('refresh') ?? null,
  verifyToken: {
    data: {
      message: '',
      code: 0,
    },
    error: {
      message: '',
      code: 0,
    },
  },
};
