import React, { useState } from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { getImageUrl } from '../../utils';
import { useHistory } from 'react-router-dom';
import { EN } from '../../constants/index';
import ForgotPassword from './ForgotPassword';
import './Login.scss';

const ErrorMessage: React.FC<{
  message?: string;
}> = ({ message }) => {
  const { push } = useHistory();
  const [showForgotPassModal, setShowForgotPassModal] = useState<boolean>(
    false
  );

  const openCloseForgotPassword = (check: boolean) => {
    setShowForgotPassModal(!showForgotPassModal);
    if (check) {
      push('/');
    }
  };

  const openForgotPasswordModal = () => {
    setShowForgotPassModal(!showForgotPassModal);
  };
  return (
    <>
      {showForgotPassModal && (
        <ForgotPassword
          modalState={showForgotPassModal}
          closeModal={(e) => openCloseForgotPassword(e)}
        />
      )}
      <div className="login_wrapper">
        <div className="rightside">
          <img src={getImageUrl('Vendor/HOMVendorlogo.svg', true)} alt="logo" />
          <div className="mt-10">
            <img src={getImageUrl('Vendor/tokenalert.svg', true)} alt="logo" />
          </div>
          <div className="mt-6">
            <PrimaryLabel
              labelText={message}
              className="text-Color_22 text-F18 font-brandonMedium"
            />
          </div>

          <div className="mt-6">
            <Button
              label={EN.login.RESET_MY_PASSWORD}
              className="login_btn"
              onClick={openForgotPasswordModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorMessage;
