import * as userTypes from './user.constant';
import { IAccountsState, initialAccountState } from './user.state';

export const userReducer = (
  state: IAccountsState = initialAccountState,
  action: { type: string; payload: any }
): IAccountsState => {
  switch (action.type) {
    case userTypes.GET_VENDOR_DETAILS:
      return {
        ...state,
        vendorDetail: action.payload,
      };
    case userTypes.SEARCH_USERS:
      return {
        ...state,
        usersList: action.payload,
        usersNotFound: action?.payload?.length === 0 ? true : false,
      };
    case userTypes.CLEAR_USER_LIST:
      return {
        ...state,
        usersList: [],
        usersNotFound: false,
      };
    case userTypes.UPDATE_CERTIFICATE:
      return {
        ...state,
        vendorDetail: { ...state.vendorDetail, ...action.payload },
      };
    default:
      return state;
  }
};
