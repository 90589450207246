import { initialAuthState, IUserLoginState } from './authentication.state';
import * as authTypes from './authentication.constant';

export const authReducer = (
  state: IUserLoginState = initialAuthState,
  action: { type: string; payload: any }
): IUserLoginState => {
  switch (action.type) {
    case authTypes.AUTH_LOGIN:
      localStorage.setItem('access', action.payload.access);
      localStorage.setItem('refresh', action.payload.refresh);
      return {
        ...state,
        refresh: action.payload.refresh,
        access: action.payload.access,
      };
    case authTypes.CLEAR_TOKEN:
      return {
        ...state,
        refresh: null,
        access: null,
      };
    case authTypes.UPDATE_REFRESH_TOKEN:
      localStorage.setItem('access', action.payload.access);
      return {
        ...state,
        access: action.payload.access,
      };
    case authTypes.VERIFY_TOKEN:
      return {
        ...state,
        verifyToken: action.payload,
      };
    case authTypes.UPDATE_TIMEZONE:
      localStorage.setItem('userTimezone', action.payload);
      return {
        ...state,
        userTimezone: action.payload,
      };
    default:
      return state;
  }
};
