import { RECORDS_PAGE } from './../../constants/index';
import {
  getEventDetailApi,
  getEventsApi,
  getFilterEventsApi,
  eventDateCountApi,
  getViewNotesDetailApi,
  assignOpenEventApi,
  getEventOperationDetailApi,
  markAttendanceApi,
  markNoShowApi,
  sendSubRequestApi,
  sendBroadcastMessageApi,
  getBuildingsListApi,
  getEmailCheckApi,
  registerNewUserApi,
  markSingleUserAttendanceApi,
  assignScheduleEventApi,
  assignSubRequestApi,
  getTimeZoneApi,
  assignScheduleEventStatusApi,
} from './events.service';
import * as eventTypes from './events.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import {
  IMarkNoShow,
  ISubRequest,
  ISendBroadcast,
  EventOperationUsersList,
  IRegisterUser,
} from '../../models';
import { IEvent } from '../../models';

export const getEvents = (
  start_date: string,
  param: string,
  page: number = RECORDS_PAGE,
  timeZone?: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const events = await getEventsApi(start_date, param, page, timeZone);
  if (events.data) {
    dispatch({
      type: eventTypes.GET_EVENTS,
      payload: events.data?.['events'],
      eventTotal: events.data?.['total'],
      page,
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const getFilteredEvents = (
  filter_date,
  param: string,
  timeZone: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const events = await getFilterEventsApi(filter_date, param, timeZone);
  if (events.data) {
    dispatch({
      type: eventTypes.GET_FILTER_EVENTS,
      payload: events.data?.['events'],
      eventTotal: events.data?.['total'],
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
  return events.data?.['total'];
};

export const clearEvents = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_EVENTS,
  });
};

export const getEventDetail = (eventId: number, elementId?: number) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getEventDetailApi(eventId, elementId);
  if (detail.data) {
    dispatch({
      type: eventTypes.GET_EVENT_DETAIL,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
  return detail.data;
};

export const getEventDateCount = (
  start_date: string,
  timeZone?: string
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const eventDateCount = await eventDateCountApi(start_date, timeZone);
  if (eventDateCount.data) {
    dispatch({
      type: eventTypes.GET_EVENT_DATE_COUNT,
      payload: eventDateCount.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getViewNotesDetail = (eventId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getViewNotesDetailApi(eventId);
  if (detail.data) {
    dispatch({
      type: eventTypes.GET_VIEW_NOTES_DETAIL,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const assignOpenEvent = (
  eventId: number,
  inviteId?: number,
  elementId?: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await assignOpenEventApi(eventId, inviteId, elementId);
  if (detail.data) {
    dispatch({
      type: eventTypes.ASSIGNED_TO_ME,
      eventId,
    });
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: detail.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (detail?.['error']?.['code'] === 400) {
    dispatch({
      type: toastTypes.ERROR_TOAST,
      toastMessage: detail?.['error']?.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getEventOperationDetail = (
  eventId: number,
  elementId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getEventOperationDetailApi(eventId, elementId);
  if (detail.data) {
    dispatch({
      type: eventTypes.GET_EVENT_OPERATION_DETAIL,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const markAttendance = (
  eventId: number,
  markUserAttendance: EventOperationUsersList[],
  elementId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markAttendanceApi(eventId, markUserAttendance);
  if (detail?.data) {
    const getEventOptionalDetail = await getEventOperationDetailApi(
      eventId,
      elementId
    );
    if (getEventOptionalDetail?.data) {
      dispatch({
        type: eventTypes.GET_EVENT_OPERATION_DETAIL,
        payload: getEventOptionalDetail.data,
      });
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: detail.data.message,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  }
};

export const markNoShow = (eventId: number, data: IMarkNoShow) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markNoShowApi(eventId, data);
  if (detail?.data) {
    dispatch({
      type: eventTypes.MARK_NOSHOW,
      eventId,
      status: data.status,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const sendSubRequest = (
  eventId: number,
  data: ISubRequest,
  elementId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const subRequest = await sendSubRequestApi(eventId, data, elementId);
  if (subRequest?.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: subRequest.data?.['message'],
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const markedEventStart = (
  data: IEvent,
  eventId: number,
  page?: number
) => async (dispatch) => {
  dispatch({
    type: eventTypes.MARK_EVENT_START,
    payload: data,
    page,
    eventId,
  });
};

export const sendBroadcastMessage = (
  eventId: number,
  data: ISendBroadcast
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const broadcast = await sendBroadcastMessageApi(eventId, data);
  if (broadcast?.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: broadcast.data?.['message'],
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const openChatWindow = () => async (dispatch) => {
  dispatch({
    type: eventTypes.OPEN_CHAT_WINDOW,
  });
};

export const markAttendanceLocally = (
  tenantId?: number,
  attended?: boolean
) => async (dispatch) => {
  const data = {
    attended,
  };
  dispatch({
    type: eventTypes.MARK_ATTENDANCE_LOCALY,
    payload: data,
    tenantId,
  });
};

export const getBuildingsList = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const buildingsList = await getBuildingsListApi();
  if (buildingsList.data) {
    dispatch({
      type: eventTypes.GET_BUILDINGS_LIST,
      payload: buildingsList.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const isEmailExists = (email: string) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const details = await getEmailCheckApi(email);
  if (details.data) {
    dispatch({
      type: eventTypes.EMAIL_CHECK,
      payload: details.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
export const registerNewUser = (
  userObj: IRegisterUser,
  eventId: number,
  elementId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await registerNewUserApi(userObj, eventId);
  if (detail?.data) {
    const getEventOptionalDetail = await getEventOperationDetailApi(
      eventId,
      elementId
    );
    if (getEventOptionalDetail?.data) {
      dispatch({
        type: eventTypes.GET_EVENT_OPERATION_DETAIL,
        payload: getEventOptionalDetail.data,
      });
      dispatch({
        type: eventTypes.MARK_ATTENDANCE,
        payload: detail.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  }
};

export const clearMarkAttendance = () => async (dispatch) => {
  const data = {
    message: '',
  };
  dispatch({
    type: eventTypes.MARK_ATTENDANCE,
    payload: data,
  });
};

export const markSingleUserAttendance = (
  eventId: number,
  markUserAttendance: EventOperationUsersList,
  elementId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await markSingleUserAttendanceApi(eventId, markUserAttendance);
  if (detail?.data) {
    const getEventOptionalDetail = await getEventOperationDetailApi(
      eventId,
      elementId
    );
    if (getEventOptionalDetail?.data) {
      dispatch({
        type: eventTypes.GET_EVENT_OPERATION_DETAIL,
        payload: getEventOptionalDetail.data,
      });
      dispatch({
        type: eventTypes.MARK_ATTENDANCE,
        payload: detail.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  }
};

export const clearEmailExistCheck = () => async (dispatch) => {
  const data = {
    status: false,
  };
  dispatch({
    type: eventTypes.EMAIL_CHECK,
    payload: data,
  });
};

export const assignScheduleEvent = (
  scheduleId: number,
  weekday: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await assignScheduleEventApi(scheduleId, weekday);
  if (detail.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: detail.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return true;
  } else if (detail?.['error']?.['code'] === 400) {
    dispatch({
      type: toastTypes.ERROR_TOAST,
      toastMessage: detail?.['error']?.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const assignScheduleEventStatus = (
  scheduleId: number,
  weekday: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await assignScheduleEventStatusApi(scheduleId, weekday);
  if (detail.data) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
  return detail.data;
};

export const assignSubRequest = (
  subrequestId: number,
  elementId: number | null
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await assignSubRequestApi(subrequestId, elementId);
  if (detail.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: detail.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (detail?.['error']?.['code'] === 400) {
    dispatch({
      type: toastTypes.ERROR_TOAST,
      toastMessage: detail?.['error']?.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getTimeZone = () => async (dispatch) => {
  const timeZoneApiResponse = await getTimeZoneApi();
  if (timeZoneApiResponse?.data) {
    dispatch({
      type: eventTypes.GET_TIMEZONE,
      payload: timeZoneApiResponse?.data,
    });
  }
};
