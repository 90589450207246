import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EN } from '../../constants';
import { EventOperationCallList } from '../../models';
import { getImageUrl } from '../../utils';

const CallList: React.FC<{
  callList: EventOperationCallList[];
}> = ({ callList = [] }) => {
  return (
    <>
      <PrimaryLabel
        labelText={EN.event.CALL_LIST}
        className={`block text-center mb-${
          callList?.length <= 0 ? '4' : '8'
        } text-EventHeader font-brandonMedium text-Color_22`}
      />
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 w-auto md:w-fit margin-auto">
        {callList?.length > 0 ? (
          callList.map((item: EventOperationCallList, index: number) => {
            return (
              <div className="w-full md:w-23 call-list" key={index}>
                <PrimaryLabel
                  labelText={item.vendorName}
                  className="block text-HeaderFont"
                />
                {item.vendorContact && (
                  <div className="my-2">
                    <img
                      src={getImageUrl('Vendor/phone-callist.svg', true)}
                      alt="call"
                      className="inline-flex pr-4"
                    />
                    <PrimaryLabel
                      labelText={item.vendorContact}
                      className="text-InputFontCommon"
                    />
                  </div>
                )}
                <div className="my-2">
                  <img
                    src={getImageUrl('Vendor/Email-callist.svg', true)}
                    alt="email"
                    className="inline-flex pr-4"
                  />
                  <PrimaryLabel
                    labelText={item.vendorEmail}
                    className="text-InputFontCommon"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center col-span-2">
            <PrimaryLabel
              labelText={EN.event.EMPTY_CALL_LIST}
              className="text-MobileFont pb-12"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CallList;
