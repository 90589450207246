import {
  IContactAdmin,
  IContactAdminResponse,
  IVendorDetail,
} from './../../models/user';
import * as userTypes from './user.constant';
import * as toastTypes from '../toast/toast.constant';
import * as loadingTypes from '../loader/loader.constant';
import {
  contactAdminApi,
  getSearchUsersApi,
  getVendorDetailApi,
  updateVendorSettingsApi,
} from './user.service';
import { IUserDetailResponse } from '../../models';
import { TOAST_MESSAGES } from '../../constants/index';

export const getVendorDetail = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getVendorDetailApi();
  if (detail.data) {
    dispatch({
      type: userTypes.GET_VENDOR_DETAILS,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateVendorSettings = (data: IVendorDetail) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedUser: IUserDetailResponse = await updateVendorSettingsApi(data);
  if (updatedUser.data) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.user.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const contactAdmin = (data: IContactAdmin) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response: IContactAdminResponse = await contactAdminApi(data);
  if (response.data) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.contact.email,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const searchUsers = (userName: string) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const detail = await getSearchUsersApi(userName);
  if (detail.data) {
    dispatch({
      type: userTypes.SEARCH_USERS,
      payload: detail.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearUserList = () => async (dispatch) => {
  dispatch({
    type: userTypes.CLEAR_USER_LIST,
  });
};

export const updateCertificate = (data: IVendorDetail) => async (dispatch) => {
  dispatch({
    type: userTypes.UPDATE_CERTIFICATE,
    payload: data,
  });
};
