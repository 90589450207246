import React, { Fragment, useEffect, useState } from 'react';
import { PrimaryLabel, VendorEventCard } from '@alfredclub/hom-planner-theme';
import { useSelector, useDispatch } from 'react-redux';
import { IEvent, TinyObject } from '../../models';
import {
  getEventDetail,
  IEventState,
  getEventOperationDetail,
  markedEventStart,
  assignScheduleEventStatus,
} from '../../redux/events';
import {
  checkPastEvent,
  checkTodaysEvent,
  getDayAndTime,
  getImageUrl,
  checkFutureEvent,
  getDateFormated,
} from '../../utils';
import { ILoaderState } from '../../redux/loader';
import CommonLoader from '../Common/CommonLoader';
import {
  EN,
  EVENT_DAY_FORMAT,
  EVENT_TIME_FORMAT,
  defaultTimezoneAbbreviation,
  defaultTimezone,
} from '../../constants';
import { useHistory } from 'react-router';
import { openAssignModal } from '../../redux/modal/modal.action';
import { IModalState } from '../../redux/modal/modal.state';
import SelfAssignPopup from './SelfAssignPopup';
import { useLocation } from 'react-router-dom';
import { IAccountsState } from '../../redux/user';
import Config from '../../config/environment';

const EventCard: React.FC<{
  tab?: string;
}> = ({ tab }) => {
  const [pageNo] = useState<number>(0);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const scheduleId = query.get('scheduleId');
  const weekday = query.get('weekday');
  const [openEventId, setOpenEvent] = useState<number>(0);
  const [openElementId, setOpenElementId] = useState<number>(0);

  const { events } = useSelector(
    (state: { events: IEventState }) => state.events
  );

  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const { openAssign } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );

  useEffect(() => {
    async function getEventDetails() {
      if (scheduleId) {
        const scheduleStatus = await dispatch(
          assignScheduleEventStatus(Number(scheduleId), Number(weekday))
        );
        if (scheduleStatus?.['accepted']) {
          if (window.location.hostname === 'localhost') {
            window.location.href = `http://localhost:3000/event-assigned`;
          } else {
            window.location.href = `${Config.VENDOR_URL}/event-assigned`;
          }
        } else {
          await dispatch(openAssignModal());
        }
      }
    }
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, scheduleId]);

  const onEventDetail = async (eventId: number, elementId: number) => {
    await dispatch(getEventDetail(eventId, elementId));
    await dispatch(getEventOperationDetail(eventId, elementId));
    push(`/detail/${eventId}/?tab=tasks&elementId=${elementId}`);
  };

  const onAssignToMe = async (eventId: number, elementId: number) => {
    await dispatch(openAssignModal());
    setOpenEvent(eventId);
    setOpenElementId(elementId);
  };

  const timerToggle = (eventId: number, isEventLive: boolean) => {
    if (!isEventLive && tab === EN.tabs.UPCOMING.toLowerCase()) {
      const data = {
        isLiveEvent: true,
      };
      dispatch(markedEventStart(data, eventId, pageNo));
    }
  };

  const showAssignedTaskIcon = (isTask, isInventory) => {
    if (isInventory) {
      return getImageUrl('Vendor/Inventory.svg', true);
    } else if (isTask) {
      return getImageUrl('Vendor/Tasks.svg', true);
    } else if (isTask && isInventory) {
      return getImageUrl('Vendor/Tasks-Inventory.svg', true);
    }
  };

  const TimeZoneValue = (virtual: boolean, zoneValue: string) => {
    return virtual
      ? vendorDetail?.timezone?.id
        ? vendorDetail?.timezone?.zoneValue
        : defaultTimezone
      : zoneValue;
  };
  return (
    <>
      {openAssign && (
        <SelfAssignPopup
          modalState={openAssign}
          eventId={openEventId}
          scheduleId={Number(scheduleId)}
          weekday={Number(weekday)}
          elementId={Number(openElementId)}
        />
      )}
      <div className="card-wrapper">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-0 event-card w-auto md:w-fit">
          {events.length === 0 && !loading && (
            <PrimaryLabel
              labelText={`No events available !`}
              className="col-span-2 text-center text-MobileFont md:text-HeaderFont pt-8"
            />
          )}
          {events.map((item: IEvent) => {
            return item?.['elements'].map(
              (elementObj: TinyObject, index: number) => {
                return (
                  <>
                    {item?.['isPackage'] &&
                    elementObj?.id &&
                    elementObj?.id === item.serviceId ? (
                      <></>
                    ) : (
                      <Fragment key={index}>
                        <VendorEventCard
                          eventImage={
                            item.image ? getImageUrl(item.image) : null
                          }
                          eventTitle={
                            elementObj?.id && elementObj?.id !== item.serviceId
                              ? `${elementObj?.name} at ${item.displayName}`
                              : item.displayName
                          }
                          eventDayMonth={
                            item.virtual
                              ? getDayAndTime(
                                  item.startDatetime,
                                  EVENT_DAY_FORMAT,
                                  TimeZoneValue(item.virtual, item.zoneValue)
                                )
                              : getDateFormated(
                                  item.sourceDate,
                                  EVENT_DAY_FORMAT
                                )
                          }
                          startTime={getDayAndTime(
                            item.startDatetime,
                            EVENT_TIME_FORMAT,
                            TimeZoneValue(item.virtual, item.zoneValue)
                          )}
                          endTime={getDayAndTime(
                            item.endDatetime,
                            EVENT_TIME_FORMAT,
                            TimeZoneValue(item.virtual, item.zoneValue)
                          )}
                          venue={
                            item.virtual
                              ? EN.event.ONLINE
                              : item.buildingName ?? EN.event.DUMMY_BUILDING
                          }
                          venueLocation={
                            item.virtual
                              ? EN.event.APP_STREAM
                              : item.location ?? EN.event.NO_LOCATION
                          }
                          isOnlineEvent={item.virtual}
                          isAtVenue={!item.virtual}
                          pastEvent={checkPastEvent(item.startDatetime)}
                          pastAttendeeText={`${item.attendeeCount ?? 0} ${
                            EN.event.ATTENDEES
                          }`}
                          isWhitelabel={false}
                          openEvent={item.open}
                          assignToMe={EN.openTab.ASSIGN_TO_ME}
                          isTodayEvent={
                            checkTodaysEvent(item.startDatetime) &&
                            tab === EN.tabs.UPCOMING.toLowerCase() &&
                            !checkPastEvent(item.endDatetime)
                          }
                          eventStatus={
                            item.virtual
                              ? EN.event.ONLINE_TIMER
                              : EN.event.OFFLINE_TIMER
                          }
                          startTimeInUTC={item.startDatetime}
                          onlinePreClassText={EN.event.ONLINE_PRE_TEXT}
                          offlinePreClassText={EN.event.OFFLINE_PRE_TEXT}
                          liveEventText={EN.event.LIVE_EVENT}
                          timerToggle={() =>
                            timerToggle(item.id, item.isLiveEvent)
                          }
                          isLiveEvent={item.isLiveEvent}
                          futureEvent={checkFutureEvent(item.startDatetime)}
                          futureAttendeeText={`${item.rsvpCount ?? 0} ${
                            item.rsvpCount > 1
                              ? EN.event.USER_LIST.RSVPS
                              : EN.event.RSVP
                          }`}
                          buildingCount={`${item.building ?? 0} ${
                            EN.event.BUILDINGS
                          }`}
                          buildingName={`${item.buildingName ?? ''}`}
                          onTodayEventClick={() =>
                            onEventDetail(item.id, elementObj?.id)
                          }
                          onFutureClick={() =>
                            onEventDetail(item.id, elementObj?.id)
                          }
                          onPastClick={() =>
                            onEventDetail(item.id, elementObj?.id)
                          }
                          onEventClick={() =>
                            onEventDetail(item.id, elementObj?.id)
                          }
                          onAssignToMe={() =>
                            onAssignToMe(item.id, elementObj?.id)
                          }
                          eventTimeZone={item.zoneValue}
                          timezone={
                            item.virtual
                              ? vendorDetail?.timezone?.id
                                ? vendorDetail?.timezone?.shortName
                                : defaultTimezoneAbbreviation
                              : item.shortName
                          }
                          isAssignedTask={
                            tab === EN.tabs.OPEN.toLowerCase()
                              ? false
                              : item.taskAssigned || item.inventoryAssigned
                          }
                          assignedImg={showAssignedTaskIcon(
                            item.taskAssigned,
                            item.inventoryAssigned
                          )}
                        />
                      </Fragment>
                    )}
                  </>
                );
              }
            );
          })}
        </div>
        <div className="p-8 w-full">
          <CommonLoader
            loading={loading}
            className={`${events.length !== 0 && `position-relative`}`}
          />
        </div>
      </div>
    </>
  );
};

export default EventCard;
