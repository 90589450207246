import { Button, PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { useForm } from 'react-hook-form';
import { contactAdmin } from '../../redux/user';
import { useDispatch } from 'react-redux';
import './Contact.scss';
import { IContactAdmin } from '../../models';
import { EN } from '../../constants';

const ContactUs: React.FC<{
  closePopup: () => void;
}> = ({ closePopup }) => {
  const { register, handleSubmit, errors } = useForm<IContactAdmin>();
  const dispatch = useDispatch();

  const onSubmit = async (data: IContactAdmin) => {
    await dispatch(contactAdmin(data));
    closePopup();
  };

  return (
    <div className="contact-section p-12 pt-0 md:pt-12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PrimaryLabel
          labelText={EN.contactAdmin.TITLE}
          className="sub-header contact-title block mb-4"
        />
        <PrimaryLabel labelText={EN.contactAdmin.SUB_HEADER} className="mb-4" />
        <TextArea
          label=""
          name="message"
          placeholder={EN.contactAdmin.PLACEHOLDER}
          ref={register({ required: true })}
          className="contact-textarea mt-4 mb-4"
        />
        <div className="block">
          {Object.keys(errors).length > 0 && (
            <PrimaryLabel
              labelText={EN.event.SEND_BROADCAST.ERROR_MSG}
              className="text-red-600"
            />
          )}
          <div>
            <Button
              label={EN.contactAdmin.BTN_SEND}
              isArrowShow={true}
              className="btn-send"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
