import moment from 'moment';
export const INJURY_REPORT = 'https://iamhom.typeform.com/to/t6nHPm';
export const INJURT_REPORT_TEXT = 'Injury report form';
export const EVENT_DAY_FORMAT = 'dddd, MMM D';
export const EVENT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'MM-DD-YYYY';
export const EVENT_TIME_FORMAT = 'h:mma';
export const MONTH_DATE_FORMAT = 'MMM Do';
export const CURRENT_DATE = moment().format(EVENT_DATE_FORMAT);
export const PAST_DATE = moment().subtract(1, 'days').format(EVENT_DATE_FORMAT);
export const BUILDING_NAME = 'happy';
export const PARAM = 'upcoming';
export const RECORDS_PAGE = 0;
export const RECORDS_LIMIT = 10;
export const TOAST_POSITION = 'bottomCenter';
export const TOAST_PROGRESS_BAR = false;
export const AUTH_BASE_URL = '/auth';
export const TickImage =
  'https://homh-site-media.s3.amazonaws.com/custom-uploads/tick.svg';
export const TERMS_CONDITION_LINK = 'http://www.iamhom.com/terms.html';
export const PRIVACY_POLICY = 'https://www.iubenda.com/privacy-policy/67339644';
export const defaultTimezone = 'US/Eastern';
export const defaultTimezoneAbbreviation = 'EST';
export const defaultFullTimezone = 'US/Eastern (EST)';
export const ENABLE_MARK_ATTENDANCE_TIME = 15;

export const TOAST_MESSAGES = {
  user: {
    updated: 'Settings updated successfully',
  },
  contact: {
    email: 'Message sent successfully',
  },
  login: {
    noAccess: 'Sorry, You do not have vendor permissions',
  },
};

export const tenantProfile = [
  {
    id: 1,
    label: INJURT_REPORT_TEXT,
    imgSrc: 'Vendor/Group+2434.svg',
  },
  {
    id: 2,
    label: 'Settings',
    imgSrc: 'Vendor/settings.svg',
  },
  {
    id: 3,
    label: 'Contact us',
    imgSrc: 'Vendor/contactphone.svg',
  },
  {
    id: 4,
    label: 'Log out',
    imgSrc: 'Vendor/logout.svg',
  },
  // Future reference
  // {
  //   id: 5,
  //   label: 'Mark Unavailability',
  //   imgSrc: 'Vendor/markunavailable.svg',
  // },
  // {
  //   id: 6,
  //   label: 'My HOM Coins',
  //   imgSrc: 'Vendor/homcoins.svg',
  // },
];

export const EVENT__DATE_FILTER_FORMAT = 'MMMM DD yyyy';

export const EN = {
  login: {
    LOGIN: 'Login',
    FORGOT_PASSWORD: 'Forgot Password?',
    PASSWORD: 'Password',
    EMAIL_ADDRESS: 'Email address',
    LOGIN_TO_ACCOUNT: 'Login to my account',
    ERROR_MESSAGE: 'Please fill fields marked above*',
    RESET_PASSWORD: 'Reset Password',
    FORGOT_YOUR_PASSWORD: 'Forgot Your Password?',
    FORGOT_PASSWORD_STATEMENT_1: 'It’s okay.. It happens to the best of us!',
    FORGOT_PASSWORD_STATEMENT_2:
      'Enter your email address below to receive password reset instructions',
    ENTER_NEW_PASSWORD: 'Enter new password',
    ENTER_PASSWORD: 'Enter password',
    RE_ENTER_PASSWORD: 'Re-enter password',
    CREATE_PASSWORD: 'Create Password',
    RESET_MY_PASSWORD: 'Reset my password',
    TOKEN_ERROR_MESSAGE_1: 'This token request is invalid.',
    PASSWORD_NOT_MATCHED: 'The passwords do not match',
  },
  tabs: {
    UPCOMING: 'Upcoming',
    PAST: 'Past',
    EVENT_ON: 'Events on',
    OPEN: 'Open',
  },
  topNav: {
    INJURY_REPORT: 'Injury report form',
    TIMEZONE_HEADING:
      'All your event times will change to the new timezone selected.',
    TIMEZONE_SUB_HEADING: 'Are you sure you want to change your timezone?',
  },
  openTab: {
    ASSIGN_TO_ME: 'Assign to me',
  },
  viewNotes: {
    VIEW_EVENT: 'View Event',
    VIEW_NOTES: 'View Notes',
    INFO: 'Info',
    FRONT_DESK_NUMBER: 'Front desk number',
    STREET_ADDRESS: 'Street Address',
    STUDIO_LOCATION: 'Studio Location',
    BATHROOM_ACCESS: 'Bathroom Access',
    ADMIN: 'Admin',
    ADMIN_NOTES: 'Admin Notes',
    SECURITY: 'Security',
    SECURITY_ACCESS: 'Security Access',
    TRUNK_CODES: 'Trunk Codes',
    WIFI: 'Wifi',
    WIFI_NAME_PASSWORD: 'Wifi name, Password',
    WEBSITE: 'Website',
    SPACE_VIDEO: 'Video of the space',
    WATER: 'Access to Water',
    GARBAGE: 'Garbage',
    OUTLETS: 'Outlets',
    FLOOR_PLAN: 'Floor Plan',
    NO_DETAIL: 'No details',
    SELECT_TIMEZONE: 'Select Timezone:',
    PARKING_DETAILS: 'Parking Details',
  },
  button: {
    EVENT_ACTION: 'Event Actions',
    LIVE_STREAM: 'Start Live Webstream',
    BEGIN_CHAT: 'Begin Chat',
    NO_CANCEL: 'No, cancel',
    YES_MARK_NO: 'Yes, mark as No show',
    YES_ASSIGNED_ME: 'Yes, assign to me',
    SAVE_ATTENDANCE: 'Save Attendance',
    ASSIGN_TO_ME: 'Assign to me',
    CHANGE_TIME: 'Yes, change timezone',
  },
  event: {
    VENDOR_CUT: 'Not decided yet',
    EMAIL_EXIST: 'This email already exists',
    INVALID_EMAIL: 'Invalid email address',
    ADD_USER: 'Add user',
    SELECT_BUILDING: 'Select Building',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    EMAIL_ADDRESS: 'Email Address',
    DUMMY_BUILDING: 'Dummy Building',
    USER_ADDED: 'Done. {{userName}} is added to the attendee list.',
    USER_REGISTERED:
      'Done. {{userName}} is registered and added to the event on {{Date}}.',
    ADD_ANOTHER_ATTENDEE: 'Add another attendee',
    MOBILE_ONLY: 'Live webstream feature available on desktop only',
    tabs: {
      USERS: 'Users',
      TASKS: 'Tasks',
      CALL_LIST: 'Call-List',
      INVENTORY: 'Inventory',
      MY_TASK: 'My-Tasks',
      ALL_TASK: 'All-Tasks',
    },
    USER_LIST: {
      MARK_ATTENDANCE: 'Mark Attendance',
      MARK_NO_SHOW: 'Mark as no show',
      RSVPS: 'RSVPs',
      ATTENDED: 'Attended',
      ADD_NEW_USER: '+Add user to list',
      N0_SHOW: {
        TITLE: 'Are you sure you want to mark this event as ‘No Show’?',
        PRIM_TITLE: 'This would mark number of attendees as 0',
        SEC_TITLE: 'Would you like to continue?',
      },
      EVENT_NO_SHOW: 'Event marked as no show',
      UNDO: 'Undo',
      ADD_TO_ATTENDEES: 'Add to Attendees',
      NAME_OF_EXISTING_USER: 'Name of existing user',
      ADD_TO_LIST: 'Add to list',
      REGISTER_NEW_USER: 'Register new user',
      OR: 'or',
      ENTER_FULL_NAME: 'Enter full name',
    },
    BUILDINGS: 'Buildings',
    RSVP: 'RSVP',
    ASSIGN_TO_ME: 'Assigned to me',
    NO_VENDOR: 'Not assigned yet',
    EMPTY_CALL_LIST: 'No vendor contacts to show',
    EMPTY_MY_TASK_LIST: 'No task to show',
    COMING_SOON: 'Feature coming soon',
    CALL_LIST: 'Contact list of vendors participating in this event',
    OPEN_MAP: 'Open Maps',
    VIEW_VENUE_DETAILS: 'View venue details',
    VENUE_DETAILS: 'Venue details',
    BROADCAST: 'Send broadcast to RSVPs',
    EMER_SUB: 'Request Substitute',
    ONLINE_EVENT: 'Online Event',
    AT_VENUE: 'At Venue',
    VIEW_IMAGE_VIDEO: 'View image/video',
    OPEN_MODAL: {
      TITLE:
        'You will be assigned to this event and it will appear in your upcoming tab.',
      SUB_TITLE: 'Would you like to confirm assignment?',
      SCHEDULE_TITLE:
        'You will be assigned to this schedule and the events will appear in your upcoming tab.',
    },
    LOAD_MORE: 'Load more',
    ONLINE: 'Online',
    APP_STREAM: 'Streamed on app',
    NO_LOCATION: 'TBD',
    ONLINE_TIMER: 'Online event today in ',
    OFFLINE_TIMER: 'At-venue event today in ',
    ONLINE_PRE_TEXT: 'Please start session 5 min before start time',
    OFFLINE_PRE_TEXT: 'Please reach the venue 10 min before start time',
    ATTENDEES: 'Attendees',
    LIVE_EVENT: 'Event begins now',
    SUB_REQUEST: {
      TITLE: 'Request Substitute',
      SUB_TITLE: 'Please choose a reason for the sub request below:',
      REASON: {
        1: 'I have another event at the same time',
        2: 'Accepted this event by mistake',
        3: 'Personal emergency',
        4: 'Health reasons',
        5: 'Other reason',
      },
      REQUEST_REASON: 'Reason for request',
      PLACEHOLDER: 'Type your message here',
      ERROR_MSG: 'Please select any option first',
      BTN_SEND: 'Send Request',
    },
    SEND_BROADCAST: {
      TITLE: 'Send Broadcast to RSVPs',
      SUB_TITLE: 'Type in your message to RSVPed users below ',
      BTN_SEND_MESSAGE: 'Send Message',
      PLACEHOLDER: 'Type your message here',
      ERROR_MSG: 'Please type something',
    },
    USER_NOT_REGISTERED:
      'This user is not registered with HOM. Please click below to register a new name.',
    INVENTORY: {
      ItemAssigned: 'Items assigned to you:',
      ItemNotAssigned: 'Not assigned yet !',
    },
    VENDOR_NOTES: 'Note to vendor:',
    EVENT_ASSIGNED_MESSAGE:
      'Sorry! This event is no longer available for assignment.',
  },
  vendorChat: {
    CONNECT_US: 'Connect with us and your fellow participants here',
    LIVE_CHAT: 'Live chat',
    DISABLE_CHAT: 'Chat is disabled',
    CONNECTING: 'Connecting chat...',
    HOM_ADMIN: 'hOM Admin',
    EDIT_MSG: 'Edit message',
    BTN_SUBMIT: 'Submit',
    BTN_SEND: 'Send',
    TYPE_MESSAGE: 'Type your message here',
    BTN_SEND_MESSAGE: 'Send Message',
    REFRESH: 'Please refresh the page if you are not able to see your chat',
  },
  profile: {
    BTN_SAVE: 'Save changes',
    TITLE: 'Vendor Settings',
    BASIC_DETAIL: 'Basic Details',
    BasicDetail: {
      F_NAME: 'First Name',
      L_NAME: 'Last Name',
      EMAIL: 'Email Address',
      PHONE: 'Phone number',
      BUSINESS_PHONE: 'Business Phone number',
      BIRTHDAY: 'Birthday',
    },
    LOCATION: 'Location Details',
    Location: {
      COUNTRY: 'Country',
      REGION: 'Region',
      STREET: 'Street Address',
      CURRENT_TIMEZONE: 'Current Timezone',
    },
    SYNCGOOGLE: 'Sync Google Calendar',
    SYNC_SUB_TITLE:
      'Events added to your homepage will post on your Google calendar',
    BTN_CONNECT: 'Connect',
    SERVICE: 'Service Details',
    Service: {
      BIO: 'My Bio',
      CERTIFICATE: 'Certifications',
      BTN_UPLOAD: 'Upload',
    },
    GOBACK: 'Go back to homepage',
    DELETE: 'Delete',
    UPLOADING: 'Uploading....',
  },
  contactAdmin: {
    TITLE: 'Hey, fellow HOMie!',
    SUB_HEADER: 'Type in your message below and we’ll get back to you asap!',
    PLACEHOLDER: 'Type your message here',
    BTN_SEND: 'Send message',
  },
};

export const subRequestReason = [
  {
    id: 1,
    name: EN.event.SUB_REQUEST.REASON[1],
  },
  {
    id: 2,
    name: EN.event.SUB_REQUEST.REASON[2],
  },
  {
    id: 3,
    name: EN.event.SUB_REQUEST.REASON[3],
  },
  {
    id: 4,
    name: EN.event.SUB_REQUEST.REASON[4],
  },
  {
    id: 5,
    name: EN.event.SUB_REQUEST.REASON[5],
  },
];

export const tenantProfileMenu = [
  {
    id: 1,
    label: `${localStorage.getItem('userTimezone') ?? 'US/Eastern'} timezone`,
    imgSrc: 'Vendor/timezone-icon.svg',
  },
  {
    id: 2,
    label: 'Mark Unavailability',
    imgSrc: 'Vendor/markunavailable.svg',
  },
  {
    id: 3,
    label: 'My HOM Coins',
    imgSrc: 'Vendor/homcoins.svg',
  },
  {
    id: 4,
    label: 'Settings',
    imgSrc: 'Vendor/settings.svg',
  },
  {
    id: 5,
    label: 'Contact us',
    imgSrc: 'Vendor/contactphone.svg',
  },
  {
    id: 5,
    label: INJURT_REPORT_TEXT,
    imgSrc: 'Vendor/Group+2434.svg',
  },
  {
    id: 6,
    label: 'Log out',
    imgSrc: 'Vendor/logout.svg',
  },
];

export const defaultESTTime = 'EST';
