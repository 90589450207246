import React, { useEffect, useState } from 'react';
import { Calendar, Tabs } from '@alfredclub/hom-planner-theme';
import EventCard from './EventCard';
import { useSelector, useDispatch } from 'react-redux';
import {
  convertDateToEventDateFormat,
  onTabChange,
  setQueryParameters,
  getQueryParameters,
  convertDateToFilterDateFormat,
  getPastDates,
  getFormatedDated,
} from '../../utils';
import {
  clearEvents,
  getEventDateCount,
  getEvents,
  getFilteredEvents,
  IEventState,
} from '../../redux/events';
import { CURRENT_DATE, EN, defaultTimezone } from '../../constants';
import { IAccountsState } from '../../redux/user';
import { ICalendarObject } from '../../models/calendar';
import './EventListing.scss';
import { useFetch } from '../../hooks';

const EventListing = () => {
  const { eventDateCount, eventCount, events } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const [tab, setTab] = useState<string>(EN.tabs.UPCOMING.toLowerCase());
  const [filterDate, setFilterDate] = useState<string | null>(null);
  const [monthDate, setMonthDate] = useState<string>(CURRENT_DATE);
  const [monthSelected, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [isFetching, setIsFetching] = useState(false);
  const [pageNo, setPage] = useState(1);
  const dispatch = useDispatch();

  useFetch(clearEvents);
  const handleScroll = () => {
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }
    setIsFetching(true);
  };
  let timer = null;
  const fetchData = async () => {
    if (events.length < eventCount) {
      clearTimeout(timer);
      return (timer = setTimeout(async () => {
        await getEvent(
          getQueryParameters('tab') ?? EN.tabs.UPCOMING.toLowerCase(),
          pageNo
        );
        setPage(pageNo + 1);
        setIsFetching(false);
      }, 1000));
    }
  };

  useEffect(() => {
    if (!isFetching) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const onMonthChange = async (data: ICalendarObject) => {
    setFilterDate(null);
    const month = data.month < 9 ? `0${data.month + 1}` : data.month + 1;
    const date = getFormatedDated(`${data.year}-${month}-01`);
    const currentDate = new Date().getDate();
    const currentMonth = new Date().getMonth();
    setMonthDate(date);
    setSelectedMonth(data.month + 1);
    if (data.month >= currentMonth) {
      setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
    } else {
      setQueryParameters(EN.tabs.PAST.toLowerCase(), 'tab');
    }
    setPage(1);
    setIsFetching(false);
    await dispatch(clearEvents());
    await dispatch(
      getEvents(
        currentMonth !== data.month
          ? date
          : getFormatedDated(`${data.year}-${data.month + 1}-${currentDate}`),
        EN.tabs.UPCOMING.toLowerCase(),
        0,
        vendorDetail?.timezone?.id
          ? vendorDetail?.timezone?.zoneValue
          : defaultTimezone
      )
    );
    await dispatch(
      getEventDateCount(
        currentMonth !== data.month
          ? date
          : getFormatedDated(`${data.year}-${data.month + 1}-${currentDate}`),
        vendorDetail?.timezone?.id
          ? vendorDetail?.timezone?.zoneValue
          : defaultTimezone
      )
    );
  };
  const getEvent = async (tabName: string, page?: number) => {
    await dispatch(
      getEvents(
        tabName === EN.tabs.PAST.toLowerCase()
          ? getPastDates(monthSelected)
          : monthDate,
        onTabChange(tabName),
        page,
        vendorDetail?.timezone?.id
          ? vendorDetail?.timezone?.zoneValue
          : defaultTimezone
      )
    );
  };

  useEffect(() => {
    async function getEventData() {
      await getEvent(getQueryParameters('tab'));
      await dispatch(
        getEventDateCount(
          CURRENT_DATE,
          vendorDetail?.timezone?.id
            ? vendorDetail?.timezone?.zoneValue
            : defaultTimezone
        )
      );
      setQueryParameters(getQueryParameters('tab'), 'tab');
    }
    setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
    if (vendorDetail.hasOwnProperty('timezone')) {
      getEventData();
    }
    window.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredEvents = async (date: Date | null) => {
    if (date) {
      const filter_date = convertDateToEventDateFormat(date);
      const filter_event_date_format = convertDateToFilterDateFormat(date);
      const filterEventCount = await dispatch(
        getFilteredEvents(
          filter_date,
          EN.tabs.UPCOMING.toLowerCase(),
          vendorDetail?.timezone?.id
            ? vendorDetail?.timezone?.zoneValue
            : defaultTimezone
        )
      );
      setQueryParameters(
        `${filterEventCount} ${EN.tabs.EVENT_ON.toLowerCase()} ${filter_event_date_format}`,
        'tab'
      );
      setFilterDate(filter_event_date_format);
      return;
    }
    setFilterDate(null);
    setQueryParameters(EN.tabs.UPCOMING.toLowerCase(), 'tab');
    await getEvent(EN.tabs.UPCOMING.toLowerCase());
  };
  return (
    <>
      <div className="md:container md:mx-auto mt-20 p-4 md:p-0 listing-wrapper ">
        <Calendar
          isToggleCalendar={false}
          selectedClass="date-selected"
          hoverClass="date-hover"
          showDots={true}
          eventCount={eventDateCount}
          onMonthOrYearChange={(e: ICalendarObject) => onMonthChange(e)}
          setCalendarDate={(e) => filteredEvents(e)}
        />
        <Tabs
          className="mt-8 mb-8"
          onTabClick={async (tabName: string) => {
            await dispatch(clearEvents());
            await getEvent(tabName);
            setIsFetching(false);
            setPage(1);
            setTab(tabName);
          }}
          buttonColor="#f36b7f"
          imgClass="tab-icon"
          isWhiteLabel={false}
        >
          <div
            data-label={
              filterDate
                ? `${eventCount} ${
                    EN.tabs.EVENT_ON
                  } ${filterDate.toUpperCase()}`
                : EN.tabs.UPCOMING
            }
            data-click={true}
          >
            <EventCard tab={tab} />
          </div>
          {!filterDate && (
            <div data-label="Past" data-click={true}>
              <EventCard tab={tab} />
            </div>
          )}
          {!filterDate && (
            <div data-label="Open" data-click={true}>
              <EventCard tab={tab} />
            </div>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default EventListing;
