import React, { useEffect, useState } from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { EN, DATE_FORMAT } from '../constants';
import { useSelector, useDispatch } from 'react-redux';
import { IEventState, getTimeZone } from '../redux/events';
import { IUserLoginResponse } from '../models';
import CommonLoader from '../components/Common/CommonLoader';
import { getImageUrl, getDateFormated } from '../utils';
import { ButtonType } from '../enums';
import {
  IAccountsState,
  updateVendorSettings,
  getVendorDetail,
} from '../redux/user';

const TimezoneModal: React.FC<{
  timeZoneModalClose: () => void;
}> = ({ timeZoneModalClose }) => {
  const dispatch = useDispatch();
  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  const { timezones } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const [zonLoading, setZoneLoading] = useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = useState<object>();
  const selectTimeZone = async (timeZone: any) => {
    setSelectedTimezone(timeZone);
  };
  useEffect(() => {
    async function getVendor() {
      setZoneLoading(true);
      if (timezones?.length === 0) {
        await dispatch(getTimeZone());
      }
      setZoneLoading(false);
    }
    if (access) {
      getVendor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);
  const saveTimeZone = async () => {
    if (selectedTimezone?.['fullName']) {
      vendorDetail.timezone = selectedTimezone;
      vendorDetail.birthday = getDateFormated(
        vendorDetail.birthday,
        DATE_FORMAT
      );
      setZoneLoading(true);
      await dispatch(updateVendorSettings(vendorDetail));
      await dispatch(getVendorDetail());
      setZoneLoading(false);
      timeZoneModalClose();
    }
  };
  return (
    <>
      <div className="pl-10 timezone_list">
        <CommonLoader loading={zonLoading} />
        <PrimaryLabel
          labelText={EN.viewNotes.SELECT_TIMEZONE}
          className="text-F18 font-bold"
        />
        <ul className="w-full pt-4 pb-6">
          {timezones.map((item: any, index: number) => {
            return (
              <li
                className="pb-2"
                key={index}
                onClick={() => selectTimeZone(item)}
              >
                <PrimaryLabel
                  labelText={`${item.fullName}`}
                  className="text-F17 "
                />
                {(selectedTimezone?.['zoneValue'] ||
                  vendorDetail?.timezone?.zoneValue) === item?.zoneValue && (
                  <span className="float-right pr-8">
                    <img
                      src={getImageUrl(
                        'Vendor/timezone-selected-icon.svg',
                        true
                      )}
                      alt="timezone"
                    />
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <Button
        type={ButtonType.BUTTON}
        className="absolute block w-full"
        label={EN.profile.BTN_SAVE}
        onClick={saveTimeZone}
        backgroundColor="#f36b7f"
      />
    </>
  );
};

export default TimezoneModal;
