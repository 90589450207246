import {
  Button,
  Dropdown,
  PrimaryLabel,
  SlideDrawer,
  Tabs,
  TenantTopnav,
} from '@alfredclub/hom-planner-theme';
import React, { useState, useEffect } from 'react';
import {
  EN,
  EVENT_DAY_FORMAT,
  EVENT_TIME_FORMAT,
  defaultTimezoneAbbreviation,
  defaultTimezone,
} from '../../constants';
import { ButtonType, EventAction } from '../../enums';
import {
  checkPastEvent,
  getDayAndTime,
  getImageUrl,
  getQueryParameters,
  getDateFormated,
} from '../../utils';
import CallList from './CallList';
import TaskList from './TaskList';
import UsersList from './UsersList';
import InventoryList from './Inventory';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEventDetail,
  getViewNotesDetail,
  IEventState,
  getEventOperationDetail,
  openChatWindow,
} from '../../redux/events';
import ViewNotesDetail from './ViewNotesDetail';
import { IModalState } from '../../redux/modal/modal.state';
import SubRequest from './SubRequest';
import {
  openAssignModal,
  openSubRequestModal,
} from '../../redux/modal/modal.action';
import SendBroadcast from './SendBroadcast';
import SelfAssignPopup from './SelfAssignPopup';
import { useLocation, useParams } from 'react-router-dom';
import { IAccountsState } from '../../redux/user';
import Config from '../../config/environment';

const EventDescription: React.FC<{
  showEventAction?: boolean;
  showMobileMessage?: boolean;
  showTabs?: boolean;
  showLiveStreamButtons?: boolean;
  isVendorChat?: boolean;
}> = ({
  showEventAction = true,
  showTabs = true,
  showMobileMessage = true,
  showLiveStreamButtons = true,
  isVendorChat,
}) => {
  const [activeTab, setActiveTab] = useState<string>('users');
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [broadcast, setBroadcastState] = useState<boolean>(false);
  const dispatch = useDispatch();
  const params = useParams();
  const query = new URLSearchParams(useLocation().search);
  const callsheetPermissions = query.get('callsheet')
    ? JSON.parse(atob(query.get('callsheet')))
    : {};

  const eventId = params['eventId']
    ? parseInt(params['eventId'], 10)
    : Number(getQueryParameters('eventId'));
  const inviteId = query.get('inviteId');
  const subrequestId = query.get('subrequestId');
  const elementId = query.get('elementId');

  const { eventDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { eventOperationalDetail, eventDetailFetched } = useSelector(
    (state: { events: IEventState }) => state.events
  );

  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  useEffect(() => {
    async function getEventDetails() {
      let eventApiResponse;
      if (!eventDetailFetched) {
        eventApiResponse = await dispatch(
          getEventDetail(eventId, Number(elementId))
        );
        await dispatch(getEventOperationDetail(eventId, Number(elementId)));
      }
      if (inviteId || subrequestId) {
        if (eventApiResponse && eventApiResponse?.['open']) {
          await dispatch(openAssignModal());
        }
        if (eventApiResponse && !eventApiResponse?.['open']) {
          if (window.location.hostname === 'localhost') {
            window.location.href = `http://localhost:3000/event-assigned`;
          } else {
            window.location.href = `${Config.VENDOR_URL}/event-assigned`;
          }
        }
      }
    }
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetailFetched, dispatch, eventId]);

  const { openSubRequest, openAssign } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const options = [
    {
      id: '1',
      label: (
        <div className="inline-flex">
          <img
            src={getImageUrl('Vendor/openmaps.svg', true)}
            className="pr-4"
            alt="openmap"
          />
          <PrimaryLabel
            labelText={EN.event.OPEN_MAP}
            className="text-MobileFont"
          />
        </div>
      ),
    },
    {
      id: '3',
      label: (
        <div
          className={`inline-flex ${
            Number(inviteId) > 0 ||
            (eventDetail?.['open'] && !eventDetail?.['isPackage'])
              ? 'opacity-25 pointer-events-none cursor-not-allowed'
              : ''
          }`}
        >
          <img
            src={getImageUrl('Vendor/sendbroadcast.svg', true)}
            className="pr-4"
            alt="broadcast"
          />
          <PrimaryLabel
            labelText={EN.event.BROADCAST}
            className="text-MobileFont"
          />
        </div>
      ),
    },
    {
      id: '4',
      label: (
        <div
          className={`inline-flex ${
            Number(inviteId) > 0 ||
            (eventDetail?.['open'] && !eventDetail?.['isPackage'])
              ? 'opacity-25 pointer-events-none cursor-not-allowed'
              : ''
          }`}
        >
          <img
            src={getImageUrl('Vendor/sendsubrequest.svg', true)}
            className="pr-4"
            alt="sub-request"
          />
          <PrimaryLabel
            labelText={EN.event.EMER_SUB}
            className="text-MobileFont"
          />
        </div>
      ),
    },
  ];

  const sendVendorToChat = async () => {
    await dispatch(openChatWindow());
    window.open(
      `/chat/${eventId}/?livestreamId=${
        eventDetail?.['livestreamId']
      }&elementId=${Number(elementId)}`,
      '_blank'
    );
  };

  const startLiveStream = () => {
    window.open(`${eventOperationalDetail?.['customStreamUrl']}`, '_blank');
  };

  const onAssignToMe = async () => {
    await dispatch(openAssignModal());
  };

  const filterOption = () => {
    if (eventDetail?.['virtual']) {
      return options.filter((option) => option.id !== '1');
    } else if (callsheetPermissions?.['showMaps']) {
      return options.filter((option) => option.id === '1');
    } else {
      return options;
    }
  };
  const timeZone = eventDetail.virtual
    ? vendorDetail?.timezone?.id
      ? vendorDetail?.timezone?.zoneValue
      : defaultTimezone
    : eventDetail?.eventTimezone?.zoneValue;
  const timeZoneAbreviation = eventDetail.virtual
    ? vendorDetail?.timezone?.id
      ? vendorDetail?.timezone?.shortName
      : defaultTimezoneAbbreviation
    : eventDetail?.eventTimezone?.shortName;
  const EventActions = () => {
    return (
      <div>
        <Dropdown
          label=""
          ddlClass="float-left z-10 md:float-right relative top-6 md:top-0 md:left-0"
          placeholder=""
          options={filterOption()}
          labelKey="label"
          valueKey="id"
          ref={() => ({})}
          onOptionChange={(e) => {
            onEventAction(Number(e?.['id']));
          }}
          classNamePrefix="event-action"
          sectionClass="eventaction-wrapper float-left md:float-right"
          isCustomDropIcon={true}
        />
        <Button
          className="px-simple-button md:flex pl-3 pr-3 relative md:left-15 md:top-1 float-left md:float-right bottom-5"
          label={EN.button.EVENT_ACTION}
          isLeftIcon={true}
          leftIcon={getImageUrl('Vendor/eventactions.svg', true)}
          btnBorderColor="#a9a9a9"
          buttonText="#3f3f3f"
          type={ButtonType.BUTTON}
        />
      </div>
    );
  };

  const onTabChange = async () => {
    setActiveTab(activeTab);
  };

  const onEventAction = (id: number) => {
    switch (id) {
      case EventAction.OPENMAP:
        return openGoogleMaps();
      case EventAction.BROADCAST:
        return sendBroadcastMessage();
      case EventAction.SUBREQUEST:
        return openSubRequestPopup();
      default:
        return null;
    }
  };

  const toggleViewNotesDrawer = async () => {
    await dispatch(getViewNotesDetail(eventId));
    setDrawerState(true);
  };

  const openGoogleMaps = async () => {
    const locationName = eventDetail?.buildingAddress;
    if (locationName) {
      const replacedName = locationName.replace(/ /g, ',');
      window.open(`https://maps.google.com/?q=1200 ${replacedName}`, '_blank');
    }
  };

  const openSubRequestPopup = async () => {
    if (!inviteId && (!eventDetail?.['open'] || eventDetail?.['isPackage'])) {
      await dispatch(openSubRequestModal());
    }
  };

  const sendBroadcastMessage = () => {
    if (!inviteId && (!eventDetail?.['open'] || eventDetail?.['isPackage'])) {
      setBroadcastState(!broadcast);
    }
  };

  const onCloseDrawer = () => {
    setDrawerState(false);
    setBroadcastState(false);
  };

  return (
    <>
      {callsheetPermissions?.['showNavbar'] && (
        <TenantTopnav
          onLogoClick={() => {}}
          isWhiteLabel={false}
          isVendor={true}
          homLogo={getImageUrl('Vendor/HOMVendorlogo.svg', true)}
          showRightSideIcons={false}
          showTimeZone={false}
        />
      )}
      <SlideDrawer
        show={drawerState || broadcast}
        showCloseIcon={true}
        closePane={onCloseDrawer}
        showIconClass="p-8 md:p-0"
        showCloseText={broadcast ? false : true}
        showIconDivClass="py-0 md:pt-3"
      >
        {drawerState ? (
          <div className="notes-description-wrapper h-full">
            <div className="first-child md:pt-12">
              <PrimaryLabel
                labelText={EN.event.VENUE_DETAILS}
                className="block h-auto view-notes-heading mb-4"
              />
            </div>
            <ViewNotesDetail isOnline={eventDetail?.['virtual'] ?? false} />
          </div>
        ) : (
          <SendBroadcast
            eventId={eventDetail?.['id']}
            closePopup={onCloseDrawer}
          />
        )}
      </SlideDrawer>
      {openSubRequest && (
        <SubRequest
          modalState={openSubRequest}
          eventId={eventDetail?.['id']}
          elementId={Number(elementId)}
        />
      )}
      {openAssign && (
        <SelfAssignPopup
          modalState={openAssign}
          eventId={eventId}
          inviteId={Number(inviteId)}
          subrequestId={Number(subrequestId)}
          elementId={Number(elementId)}
          fromDesc={true}
        />
      )}
      {eventDetail?.['virtual'] && showMobileMessage && (
        <div className="md:hidden mobile-only block">
          <img
            src={getImageUrl('Vendor/nolivestreammobile.svg', true)}
            alt="icon"
            className="inline-flex p-4"
          />
          <PrimaryLabel
            labelText={EN.event.MOBILE_ONLY}
            className="text-Medium text-Color_16"
          />
        </div>
      )}
      <div
        className={`description-wrapper sm:container md:container lg:container xl:container md:mx-auto md:mt-${
          isVendorChat ? '4' : '12'
        } px-8 pt-${eventDetail?.['virtual'] ? 8 : 20} md:pt-8 md:px-8 lg:px-${
          isVendorChat ? '8' : '42'
        } lg:py-0 lg:pt-10`}
      >
        <PrimaryLabel
          labelText={
            eventDetail?.['virtual'] ? EN.event.ONLINE_EVENT : EN.event.AT_VENUE
          }
          className="text-MobileFont"
        />
        {showEventAction &&
          Object.keys(callsheetPermissions).length > 0 &&
          !eventDetail?.['virtual'] && (
            <div className="hidden md:block">
              <EventActions />
            </div>
          )}
        {showEventAction && Object.keys(callsheetPermissions).length <= 0 && (
          <div className="hidden md:block">
            <EventActions />
          </div>
        )}
        <div>
          <PrimaryLabel
            labelText={eventDetail?.['displayName']}
            className="text-EventHeader md:text-F25 block pb-2 font-brandonBold text-Color_22"
          />
          <PrimaryLabel
            labelText={eventDetail?.['description']}
            className="md:text-F10 block pb-4 font-brandonLight text-Color_22"
          />
        </div>
        <div className="event-info inline-grid gap-y-4 text-Color_5">
          <div className="inline-flex">
            <img src={getImageUrl('Vendor/Day.svg', true)} alt="day" />
            <span className="pl-4">
              {eventDetail.virtual
                ? getDayAndTime(
                    eventDetail?.['startDatetime'],
                    EVENT_DAY_FORMAT,
                    timeZone
                  )
                : getDateFormated(
                    eventDetail?.['sourceDate'],
                    EVENT_DAY_FORMAT
                  )}
            </span>
          </div>
          <div className="inline-flex">
            <img src={getImageUrl('Vendor/Time.svg', true)} alt="time" />
            <span className="time">
              {getDayAndTime(
                eventDetail?.['startDatetime'],
                EVENT_TIME_FORMAT,
                timeZone
              )}{' '}
              -{' '}
              {getDayAndTime(
                eventDetail?.['endDatetime'],
                EVENT_TIME_FORMAT,
                timeZone
              )}{' '}
              {timeZoneAbreviation}
            </span>
          </div>
          <div className="inline-flex">
            <img src={getImageUrl('Vendor/Location.svg', true)} alt="venue" />
            {eventDetail?.['virtual'] ? (
              <span className="pl-4 underline">
                {eventDetail?.['buildingCount']} {EN.event.BUILDINGS}
              </span>
            ) : (
              <span className="pl-4">
                {eventDetail?.['building'] !== ''
                  ? eventDetail?.['building']
                  : eventDetail?.['buildingName']}{' '}
                - {eventDetail?.['location']}
              </span>
            )}
          </div>
          {!isVendorChat && callsheetPermissions?.['showPrice'] && (
            <div className="inline-flex">
              <img
                src={getImageUrl('Vendor/vendorcut.svg', true)}
                alt="price"
              />
              <span className="pl-5">
                {eventDetail?.['vendorCut'] > 0
                  ? `$${eventDetail?.['vendorCut']}`
                  : EN.event.VENDOR_CUT}
              </span>
            </div>
          )}
          {!isVendorChat && Object.keys(callsheetPermissions).length <= 0 && (
            <div className="inline-flex">
              <img
                src={getImageUrl('Vendor/vendorcut.svg', true)}
                alt="price"
              />
              <span className="pl-5">
                {eventDetail?.['vendorCut'] > 0
                  ? `$${eventDetail?.['vendorCut']}`
                  : EN.event.VENDOR_CUT}
              </span>
            </div>
          )}
          {showEventAction && (
            <div className="md:hidden">
              <EventActions />
            </div>
          )}
          {!inviteId &&
            eventDetail?.['virtual'] &&
            showLiveStreamButtons &&
            !callsheetPermissions?.['ctaDisabled'] &&
            !checkPastEvent(eventDetail?.['endDatetime']) && (
              <div
                className={`hidden md:inline-flex ${
                  eventDetail?.['open'] && 'hidden md:hidden'
                }`}
              >
                <Button
                  backgroundColor="#f36b7f"
                  buttonText="#fefefe"
                  className="px-secondary-button btn-live-stream text-SubHeaderFont font-brandonBold"
                  label={EN.button.LIVE_STREAM}
                  isArrowShow={true}
                  type={ButtonType.BUTTON}
                  onClick={startLiveStream}
                />
                <Button
                  className="px-simple-button ml-4 btn-live-stream text-SubHeaderFont font-brandonMedium"
                  label={EN.button.BEGIN_CHAT}
                  onClick={sendVendorToChat}
                  type={ButtonType.BUTTON}
                />
              </div>
            )}
          {!eventDetail?.['virtual'] &&
            !eventDetail?.['open'] &&
            !Number(inviteId) && (
              <div className="md:inline-flex">
                <Button
                  className="px-simple-button font-brandonMedium float-left"
                  label={EN.event.VIEW_VENUE_DETAILS}
                  onClick={toggleViewNotesDrawer}
                  type={ButtonType.BUTTON}
                />
              </div>
            )}
          {(Number(inviteId) > 0 ||
            (eventDetail?.['open'] && !eventDetail?.['isPackage'])) &&
            !callsheetPermissions?.['ctaDisabled'] && (
              <div className={`md:inline-flex`}>
                <Button
                  backgroundColor="#f36b7f"
                  buttonText="#fefefe"
                  className="px-secondary-button text-SubHeaderFont font-brandonBold float-left"
                  label={EN.button.ASSIGN_TO_ME}
                  type={ButtonType.BUTTON}
                  onClick={onAssignToMe}
                />
                {!eventDetail?.['virtual'] && (
                  <Button
                    className="px-simple-button md:ml-4 ml-2 float-left"
                    label={EN.event.VIEW_VENUE_DETAILS}
                    onClick={toggleViewNotesDrawer}
                    type={ButtonType.BUTTON}
                  />
                )}
              </div>
            )}
        </div>

        {eventDetail?.['vendorNote'] &&
          Object.keys(callsheetPermissions).length <= 0 && (
            <div className="vendor-notes">
              <PrimaryLabel
                labelText={EN.event.VENDOR_NOTES}
                className="block text-F17"
              />
              <PrimaryLabel
                labelText={eventDetail?.['vendorNote']}
                className="italic text-F16"
              />
            </div>
          )}

        {showTabs && (
          <div>
            <Tabs
              className="mt-8 mb-8 md:mb-0"
              onTabClick={onTabChange}
              buttonColor="#f36b7f"
              imgClass="tab-icon"
              isWhiteLabel={false}
              tabClassName="h-12 event-tabs text-F16 md:text-SubHeaderFont"
              tabIcon={getImageUrl('Vendor/vendortab.svg', true)}
            >
              <div data-label={EN.event.tabs.TASKS} data-click={true}>
                <TaskList
                  taskList={eventOperationalDetail?.tasks}
                  permissions={callsheetPermissions}
                />
              </div>
              <div data-label={EN.event.tabs.CALL_LIST} data-click={true}>
                <CallList callList={eventOperationalDetail?.callList} />
              </div>
              <div data-label={EN.event.tabs.USERS} data-click={true}>
                <UsersList
                  eventId={eventDetail.id}
                  totalRsvp={eventOperationalDetail?.rsvps}
                  totalAttended={eventOperationalDetail?.attended}
                  usersList={eventOperationalDetail?.users}
                  noShow={eventDetail.markNoShow}
                  eventDate={eventDetail.startDatetime}
                  openEvent={
                    eventDetail?.['open'] && !eventDetail?.['isPackage']
                  }
                  timeZone={timeZone}
                  permissions={callsheetPermissions}
                  elementId={Number(elementId)}
                />
              </div>
              <div data-label={EN.event.tabs.INVENTORY} data-click={true}>
                <InventoryList inventory={eventOperationalDetail?.inventory} />
              </div>
            </Tabs>
          </div>
        )}
      </div>
    </>
  );
};

export default EventDescription;
