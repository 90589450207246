export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT_DETAIL = 'GET_EVENT_DETAIL';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const GET_FILTER_EVENTS = 'GET_FILTER_EVENTS';
export const GET_EVENT_DATE_COUNT = 'GET_EVENT_DATE_COUNT';
export const GET_VIEW_NOTES_DETAIL = 'GET_VIEW_NOTES_DETAIL';
export const ASSIGNED_TO_ME = 'ASSIGNED_TO_ME';
export const GET_EVENT_OPERATION_DETAIL = 'GET_EVENT_OPERATION_DETAIL';
export const MARK_NOSHOW = 'MARK_NOSHOW';
export const MARK_EVENT_START = 'MARK_EVENT_START';
export const OPEN_CHAT_WINDOW = 'OPEN_CHAT_WINDOW';
export const MARK_ATTENDANCE_LOCALY = 'MARK_ATTENDANCE_LOCALY';
export const MARK_ATTENDANCE = 'MARK_ATTENDANCE';
export const GET_BUILDINGS_LIST = 'GET_BUILDINGS_LIST';
export const EMAIL_CHECK = 'EMAIL_CHECK';
export const GET_TIMEZONE = 'GET_TIMEZONE';
