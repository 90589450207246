import React, { useState } from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { IUserLogin, IUserLoginResponse } from '../../models';
import { getImageUrl } from '../../utils';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authentication';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader';
import { EN } from '../../constants/index';
import ForgotPassword from './ForgotPassword';
import './Login.scss';

const LoginForm = () => {
  const { register, errors, handleSubmit } = useForm<IUserLogin>();

  const dispatch = useDispatch();
  const { location, replace } = useHistory();

  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  const [showForgotPassModal, setShowForgotPassModal] = useState<boolean>(
    false
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onSubmit = async (data: IUserLogin) => {
    const loginObj = {
      username: data.username.trim().toLowerCase(),
      password: data.password,
    };
    await dispatch(login(loginObj));

    // Coming from email link
    if (location.state && location.state?.['from']) {
      replace(location.state?.['from']);
    } else {
      replace(`/?tab=${EN.tabs.UPCOMING.toLowerCase()}`);
    }
  };

  const openCloseForgotPassword = () => {
    setShowForgotPassModal(!showForgotPassModal);
  };
  return (
    <>
      {access ? (
        <Redirect to="/" />
      ) : (
        <>
          {showForgotPassModal && (
            <ForgotPassword
              modalState={showForgotPassModal}
              closeModal={openCloseForgotPassword}
            />
          )}
          <div className="login_wrapper">
            <div className="rightside">
              <form onSubmit={handleSubmit(onSubmit)}>
                <img
                  src={getImageUrl('Vendor/HOMVendorlogo.svg', true)}
                  alt="logo"
                />
                <div className="mt-8">
                  <PrimaryLabel
                    labelText={EN.login.LOGIN_TO_ACCOUNT}
                    className="text-Color_22 font-brandonBold forgot_heading "
                  />
                </div>
                <div className="pt-4 mt-4">
                  <PrimaryLabel
                    labelText={EN.event.EMAIL_ADDRESS}
                    className="field_label"
                  />
                  <Input
                    label=""
                    placeholder=""
                    name="username"
                    inputType="text"
                    ref={register({
                      required: true,
                    })}
                    isError={errors.username ? true : false}
                  />
                </div>
                <div className="pt-4 mt-4">
                  <PrimaryLabel
                    labelText={EN.login.PASSWORD}
                    className="field_label"
                  />
                  <Input
                    label=""
                    placeholder=""
                    name="password"
                    inputType="password"
                    ref={register({ required: true })}
                    isError={errors.password ? true : false}
                  />
                </div>
                <span
                  onClick={openCloseForgotPassword}
                  className="field_label mt-1 float-right text-base font-medium text-Color_34 cursor-pointer"
                >
                  {EN.login.FORGOT_PASSWORD}
                </span>
                <div className="mt-12">
                  <Button
                    label={EN.login.LOGIN}
                    className="login_btn"
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LoginForm;
