import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { EN, DATE_FORMAT } from '../../constants';
import { IVendorDetail } from '../../models';
import BasicDetails from './BasicDetails';
import LocationDetail from './LocationDetail';
import ServiceDetail from './ServiceDetail';
import { useSelector, useDispatch } from 'react-redux';
import {
  getVendorDetail,
  IAccountsState,
  updateVendorSettings,
} from '../../redux/user';
import { useFetch } from '../../hooks';
import { getDateFormated } from '../../utils';

const VendorSetting = () => {
  const { register, handleSubmit, setValue, reset } = useForm<IVendorDetail>();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  useFetch(getVendorDetail);
  useEffect(() => {
    reset(vendorDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorDetail, reset]);

  const goBackToHome = () => {
    push(`/?tab=${EN.tabs.UPCOMING.toLowerCase()}`);
  };

  const onSubmit = async (data: IVendorDetail) => {
    data.certification = vendorDetail?.['certification'];
    data.birthday = getDateFormated(data.birthday, DATE_FORMAT);
    data.timezone = vendorDetail?.timezone;
    await dispatch(updateVendorSettings(data));
    push(`/?tab=${EN.tabs.UPCOMING.toLowerCase()}`);
  };

  return (
    <div className="sm:container md:container lg:container xl:container md:mx-auto  px-8 pt-8 md:pt-2 md:px-8 lg:px-42 user-profile-wrapper pb-16">
      <PrimaryLabel
        labelText={`< ${EN.profile.GOBACK}`}
        className="mb-8 block cursor-pointer"
        onClick={goBackToHome}
      />
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <BasicDetails
          register={register}
          setValue={setValue}
          vendorDetail={vendorDetail}
        />
        <LocationDetail register={register} />
        {/* TODO later implemented in future release  */}
        {/* <SyncCalendar /> */}
        <ServiceDetail register={register} vendorDetail={vendorDetail} />
      </form>
    </div>
  );
};

export default VendorSetting;
