import { PrimaryLabel, Tabs } from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EventOperationTasksList } from '../../models';
import {
  EN,
  EVENT_DAY_FORMAT,
  EVENT_TIME_FORMAT,
  defaultTimezoneAbbreviation,
  defaultTimezone,
} from '../../constants';
import {
  getDayAndTime,
  getImageUrl,
  getVendorTaskFilter,
  linkString,
} from '../../utils';
import { IEventState } from '../../redux/events';
import { IAccountsState } from '../../redux/user';

const EventTasks: React.FC<{
  taskListArray: EventOperationTasksList[];
  isMyTask: boolean;
}> = ({ taskListArray, isMyTask }) => {
  const { eventDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const timeZone = eventDetail.virtual
    ? vendorDetail?.timezone?.id
      ? vendorDetail?.timezone?.zoneValue
      : defaultTimezone
    : eventDetail?.eventTimezone?.zoneValue;
  const timeZoneAbbreviation = eventDetail.virtual
    ? vendorDetail?.timezone?.id
      ? vendorDetail?.timezone?.shortName
      : defaultTimezoneAbbreviation
    : eventDetail?.eventTimezone?.shortName;
  return (
    <div className="grid grid-cols-1 gap-6 w-auto md:w-fit margin-auto text-left">
      {taskListArray?.length > 0 ? (
        taskListArray.map((item: EventOperationTasksList, index: number) => {
          return (
            <div
              className={`w-full md:w-sliderWidth task-list h-auto`}
              key={index}
            >
              <PrimaryLabel
                labelText={`${
                  isMyTask
                    ? EN.event.ASSIGN_TO_ME
                    : item?.assignedTo?.length > 0
                    ? item?.assignedTo
                    : EN.event.NO_VENDOR
                }`}
                className={`block pb-4 md:float-right text-Medium text-Color_5 ${
                  isMyTask && 'text-Color_34'
                }`}
              />
              <PrimaryLabel
                labelText={getDayAndTime(
                  item.timeOfTask,
                  EVENT_DAY_FORMAT,
                  timeZone
                )}
                className="block text-InputFontCommon text-Color_5"
              />

              <PrimaryLabel
                labelText={`${getDayAndTime(
                  item.timeOfTask,
                  EVENT_TIME_FORMAT,
                  timeZone
                )} ${timeZoneAbbreviation}`}
                className="block text-Color_22 text-HeaderFont"
              />
              <div className="my-2">
                <PrimaryLabel
                  labelText={item.title}
                  className="block text-InputFontCommon text-Color_22 font-medium"
                />{' '}
                <PrimaryLabel
                  labelText={item.description}
                  className="text-InputFontCommon text-Color_22 break-words"
                />
              </div>
              {item.documentLink && (
                <a
                  href={linkString(item.documentLink)}
                  target="_blank"
                  className="cursor-pointer underline"
                  rel="noreferrer"
                >
                  <img
                    src={getImageUrl('Vendor/view-image-vendor.svg', true)}
                    alt="view-icon"
                    className="pr-2 inline-flex"
                  />
                  <PrimaryLabel
                    labelText={EN.event.VIEW_IMAGE_VIDEO}
                    className="vertical-middle text-Color_22 cursor-pointer"
                  />
                </a>
              )}
            </div>
          );
        })
      ) : (
        <div className="text-center col-span-2">
          <PrimaryLabel
            labelText={EN.event.EMPTY_MY_TASK_LIST}
            className="text-EventHeader"
          />
        </div>
      )}
    </div>
  );
};

const TaskList: React.FC<{
  taskList: EventOperationTasksList[];
  permissions: object;
}> = ({ taskList = [], permissions = {} }) => {
  const [activeTab, setActiveTab] = useState<string>(
    permissions?.['showAllTask'] ? 'all-tasks' : 'my-tasks'
  );
  useEffect(() => {
    setActiveTab(activeTab);
  }, [activeTab]);

  return (
    <div className="text-center">
      <Tabs
        className="mb-8"
        onTabClick={() => {}}
        buttonColor="#f36b7f"
        imgClass="hidden"
        isWhiteLabel={false}
        tabClassName={`h-12`}
        activeTabClass="my-tasks"
        selectedTab={activeTab}
      >
        {!permissions?.['showAllTask'] && (
          <div data-label={EN.event.tabs.MY_TASK} data-click={true}>
            <EventTasks
              isMyTask={true}
              taskListArray={getVendorTaskFilter(taskList, true)}
            />
          </div>
        )}
        <div data-label={EN.event.tabs.ALL_TASK} data-click={true}>
          <EventTasks isMyTask={false} taskListArray={taskList} />
        </div>
      </Tabs>
    </div>
  );
};

export default TaskList;
