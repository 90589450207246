import React, { useState, useEffect } from 'react';
import { Button, PrimaryLabel, Dropdown } from '@alfredclub/hom-planner-theme';
import { ILoaderState } from '../../../redux/loader';
import { IAccountsState } from '../../../redux/user';
import ModalPopup from '../../ReactModal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { searchUsers } from '../../../redux/user';
import { useDebounce } from '../../../hooks';
import { IEventState } from '../../../redux/events';
import {
  markSingleUserAttendance,
  clearMarkAttendance,
} from '../../../redux/events';
import { EN } from '../../../constants';
import { ButtonType } from '../../../enums';
import { replaceString, getImageUrl } from '../../../utils';
import { IUsersList, UserListElement } from '../../../models';
import './EventAttendees.scss';
import '../EventListing.scss';

const AddAttendee: React.FC<{
  modalState: boolean;
  eventId: number;
  closeModal: (check: number) => void;
  elementId: number;
}> = ({ modalState, eventId, closeModal, elementId }) => {
  const dispatch = useDispatch();
  const { debounce } = useDebounce(1000);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { usersList, usersNotFound } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const { markUserAttenDance } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const [optionList, setOptions] = useState<UserListElement[]>([]);
  const [getTenantId, setTenantId] = useState<number>(0);
  const [showError, setError] = useState<boolean>(false);
  const [showUser, setUser] = useState<string>('');
  useEffect(() => {
    if (usersList?.length > 0) {
      usersList.forEach((item: IUsersList, index: number) => {
        optionList.push({
          userName: optionElement(item, index),
          email: item.email,
          name: item.name,
          tenantId: item.tenantId,
        });
      });
      setOptions([...optionList]);
    } else {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  const { handleSubmit } = useForm<IUsersList>();

  // Custom html options for search user dropdown
  const optionElement = (item: IUsersList, i: number) => {
    return (
      <div key={i} className=" w-full justify-start">
        <PrimaryLabel
          className="text-base text-Color_22"
          labelText={item.name}
        />
        <div className="selected-user-email">
          <PrimaryLabel
            className="text-sm font-medium text-Color_22"
            labelText={item.email}
          />
        </div>
      </div>
    );
  };

  const onSubmit = async () => {
    if (!getTenantId) {
      setError(true);
      return;
    }
    const markAttendanceArray = {
      tenantId: getTenantId,
      status: true,
    };
    await dispatch(
      markSingleUserAttendance(eventId, markAttendanceArray, elementId)
    );
  };
  const findUser = async (value: any) => {
    if (typeof value === 'string' && value.length > 0) {
      await dispatch(searchUsers(value));
    }
  };
  const closeAddAttendeeModal = (check: number) => {
    setTenantId(0);
    dispatch(clearMarkAttendance());
    closeModal(check);
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={() => closeAddAttendeeModal(0)}
      className="p-8 md:p-12 pt-12 w-auto h-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 attendee-popup"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      {markUserAttenDance?.message ? (
        <div className="flex flex-col items-center">
          <img
            src={getImageUrl('Vendor/Done.svg', true)}
            className="pr-6"
            alt="Done"
          />
          <PrimaryLabel
            className="text-lg font-medium text-Color_22 mt-2"
            labelText={replaceString(
              EN.event.USER_ADDED,
              '{{userName}}',
              showUser
            )}
          />
          <div className="pt-6">
            <Button
              label={EN.event.ADD_ANOTHER_ATTENDEE}
              className={`block px-simple-button`}
              type={ButtonType.BUTTON}
              onClick={() => closeAddAttendeeModal(1)}
            />
          </div>
        </div>
      ) : (
        <>
          <PrimaryLabel
            className="text-xl font-medium text-Color_22"
            labelText={EN.event.USER_LIST.ADD_TO_ATTENDEES}
          />
          <div className="mt-4">
            <form className="w-full mt-7" onSubmit={handleSubmit(onSubmit)}>
              <PrimaryLabel
                labelText={EN.event.USER_LIST.NAME_OF_EXISTING_USER}
              />
              <PrimaryLabel labelText="*" className="text-red-500" />
              <Dropdown
                asyncDropdown={false}
                isLabelShow={false}
                label=""
                ddlClass="relative top-0 md:top-0"
                options={optionList}
                labelKey="userName"
                valueKey="name"
                loading={loading}
                ref={() => ({})}
                onOptionChange={(e) => {
                  setError(false);
                  setTenantId(e['tenantId']);
                  setUser(e['name']);
                }}
                classNamePrefix="event-action"
                isCustomDropIcon={false}
                placeholder={EN.event.USER_LIST.ENTER_FULL_NAME}
                onInputChange={(e) => {
                  setOptions([]);
                  debounce(findUser, e);
                }}
                isError={showError}
                resetValue={usersNotFound}
              />
              {usersNotFound && (
                <div className="flex flex-row">
                  <span className="pr-1">
                    <img
                      src={getImageUrl('Vendor/alertred.svg', true)}
                      alt="Alertred"
                    />
                  </span>
                  <span className="font-medium text-base user-not-registered">
                    {EN.event.USER_NOT_REGISTERED}
                  </span>
                </div>
              )}
              <div className="mt-5 flex flex-col place-items-center">
                {!usersNotFound && (
                  <>
                    <Button
                      label={EN.event.USER_LIST.ADD_TO_LIST}
                      className={`w-full md:w-full block ${
                        loading && 'button_disable'
                      }`}
                      backgroundColor="#f36b7f"
                      buttonText="#fefefe"
                      type={ButtonType.SUBMIT}
                      disabled={loading}
                    />
                    <div className="my-2">
                      <PrimaryLabel
                        className="text-base font-medium text-Color_22"
                        labelText={EN.event.USER_LIST.OR}
                      />
                    </div>
                  </>
                )}
                <Button
                  label={EN.event.USER_LIST.REGISTER_NEW_USER}
                  className={`block px-simple-button`}
                  type={ButtonType.BUTTON}
                  onClick={() => closeAddAttendeeModal(2)}
                />
              </div>
            </form>
          </div>
        </>
      )}
    </ModalPopup>
  );
};

export default AddAttendee;
