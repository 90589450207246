import React from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import ModalPopup from '../ReactModal/Modal';
import { IForgotPasswordDetails } from '../../models';
import { getImageUrl } from '../../utils';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../redux/authentication';
import { useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader';
import { EN } from '../../constants/index';
import { ButtonType } from '../../enums';
import './Login.scss';

const ForgotPassword: React.FC<{
  modalState: boolean;
  closeModal: (check: boolean) => void;
}> = ({ modalState, closeModal }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { handleSubmit, register, errors } = useForm<IForgotPasswordDetails>();
  const onSubmit = async (data: IForgotPasswordDetails) => {
    if (data.email.trim().toLowerCase().length > 0) {
      const emailObj = {
        email: data.email.trim().toLowerCase(),
      };
      await dispatch(forgotPassword(emailObj));
      closeModal(true);
    }
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={() => closeModal(false)}
      className="p-8 md:p-12 pt-12 w-auto h-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 attendee-popup"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      <div className="">
        <img src={getImageUrl('ConsumerApp/LogoHOM.svg', true)} alt="logo" />
      </div>
      <div className="mt-12">
        <PrimaryLabel
          className="font-bold font-brandonBold forgot_heading text-Color_22"
          labelText={EN.login.FORGOT_YOUR_PASSWORD}
        />
      </div>
      <div className="mt-6">
        <PrimaryLabel
          className="text-F17 font-medium font-brandonMedium text-Color_22"
          labelText={EN.login.FORGOT_PASSWORD_STATEMENT_1}
        />
      </div>
      <div className="">
        <PrimaryLabel
          className="text-F17 font-brandonRegular text-Color_22"
          labelText={EN.login.FORGOT_PASSWORD_STATEMENT_2}
        />
      </div>
      <div className="mt-5">
        <form className="w-full mt-7" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <PrimaryLabel labelText={EN.event.EMAIL_ADDRESS} />
            <Input
              label=""
              name="email"
              inputType="text"
              isError={errors?.email ? true : false}
              ref={register({
                required: true,
              })}
            />
          </div>
          <div className="mt-8 pb-20">
            <Button
              label={EN.login.RESET_PASSWORD}
              className={`block w-full md:w-full ${
                loading && 'button_disable'
              }`}
              type={ButtonType.SUBMIT}
              backgroundColor="#f36b7f"
              buttonText="#fefefe"
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </ModalPopup>
  );
};

export default ForgotPassword;
