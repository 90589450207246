import React, { useState } from 'react';
import { PrimaryLabel, Button } from '@alfredclub/hom-planner-theme';
import { useDispatch, useSelector } from 'react-redux';
import { EN, ENABLE_MARK_ATTENDANCE_TIME } from '../../constants';
import {
  markAttendance,
  markNoShow,
  markAttendanceLocally,
  clearEmailExistCheck,
  IEventState,
} from '../../redux/events';
import {
  checkFutureEvent,
  checkTodaysEvent,
  getImageUrl,
  getTimeDifference,
} from '../../utils';
import { ILoaderState } from '../../redux/loader';
import { clearUserList } from '../../redux/user';
import MarkNoShow from './MarkNoShow';
import { EventOperationUsersList } from '../../models';
import CommonLoader from '../Common/CommonLoader';
import { IModalState } from '../../redux/modal/modal.state';
import { openMarkNoShowModal } from '../../redux/modal/modal.action';
import AddAttendee from './EventAttendees/AddAttendee';
import RegisterAttendee from './EventAttendees/RegisterAttendee';
import { ButtonType } from '../../enums';

const UsersList: React.FC<{
  eventId: number;
  totalRsvp: number;
  totalAttended: number;
  usersList: EventOperationUsersList[];
  noShow: boolean;
  eventDate: string;
  openEvent: boolean;
  timeZone: string;
  permissions: object;
  elementId: number;
}> = ({
  eventId,
  totalRsvp,
  totalAttended,
  usersList = [],
  noShow,
  eventDate,
  openEvent,
  timeZone,
  permissions,
  elementId,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { eventDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );

  const { openMarkNoShow } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );

  const onMarkNoShow = async () => {
    await dispatch(openMarkNoShowModal());
  };

  const undoNoShow = async () => {
    await dispatch(markNoShow(eventId, { status: false }));
  };
  const [showAddAttendeeModal, setShowAddAttendeeModal] = useState<boolean>(
    false
  );
  const [showRegisterUserModal, setShowRegisterUserModal] = useState<boolean>(
    false
  );
  const closeAddAttendeeModal = (check: number) => {
    setShowRegisterUserModal(check === 2 ? true : false);
    setShowAddAttendeeModal(check === 1 ? true : false);
    dispatch(clearUserList());
  };

  const closeRegisterUserModal = (check: boolean) => {
    setShowRegisterUserModal(false);
    setShowAddAttendeeModal(check);
    dispatch(clearEmailExistCheck());
  };
  const NoShowMessage = () => {
    return (
      <div className="text-Color_22">
        <img
          src={getImageUrl('Vendor/markednoshow.svg', true)}
          alt="icon"
          className="inline-flex pr-2 h-5"
        />
        <PrimaryLabel
          labelText={EN.event.USER_LIST.EVENT_NO_SHOW}
          className="text-InputFontCommon noshow-message pr-3"
        />
        <PrimaryLabel
          labelText={EN.event.USER_LIST.UNDO}
          className="text-InputFontCommon underline cursor-pointer"
          onClick={undoNoShow}
        />
      </div>
    );
  };
  const markUserAttendance = async (tenantId: number, attended: boolean) => {
    dispatch(markAttendanceLocally(tenantId, attended));
  };
  const marAttendance = async () => {
    const markAttendee = [];
    usersList.forEach((item: EventOperationUsersList) => {
      markAttendee.push({
        status: item.attended,
        tenantId: item.tenantId,
      });
    });
    await dispatch(markAttendance(eventId, markAttendee, elementId));
  };

  const calculateTimeDifference = () => {
    if (eventDetail?.['startDatetime']) {
      const { minutes } = getTimeDifference(
        eventDetail?.['startDatetime'],
        timeZone
      );
      if (minutes < ENABLE_MARK_ATTENDANCE_TIME) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <CommonLoader loading={loading} />
      {openMarkNoShow && (
        <MarkNoShow modalState={openMarkNoShow} eventId={eventId} />
      )}
      {showAddAttendeeModal && (
        <AddAttendee
          modalState={showAddAttendeeModal}
          eventId={eventId}
          closeModal={(e) => closeAddAttendeeModal(e)}
          elementId={elementId}
        />
      )}
      {showRegisterUserModal && (
        <RegisterAttendee
          modalState={showRegisterUserModal}
          eventId={eventId}
          closeModal={(e) => closeRegisterUserModal(e)}
          elementId={elementId}
        />
      )}
      {noShow && (
        <div className="md:hidden mb-8">
          <NoShowMessage />
        </div>
      )}
      <div>
        <PrimaryLabel
          labelText={EN.event.USER_LIST.MARK_ATTENDANCE}
          className="text-F20 text-brandonBold text-Color_22"
        />
        {!noShow ? (
          !permissions?.['ctaDisabled'] && (
            <div
              className={`float-right text-Color_22 cursor-pointer ${
                openEvent && 'pointer-events-none opacity-50'
              }`}
              onClick={onMarkNoShow}
            >
              <img
                src={getImageUrl('Vendor/marknoshow.svg', true)}
                alt="icon"
                className="inline-flex pr-2 h-5"
              />
              <PrimaryLabel
                labelText={EN.event.USER_LIST.MARK_NO_SHOW}
                className="text-InputFontCommon underline cursor-pointer"
              />
            </div>
          )
        ) : (
          <div className="float-right hidden md:block">
            <NoShowMessage />
          </div>
        )}
        <div>
          <span className=" text-SubHeaderFont text-Color_22">
            {`${totalRsvp} ${EN.event.USER_LIST.RSVPS}`}
          </span>
          ,{' '}
          <span className=" text-SubHeaderFont text-Color_22">
            {`${totalAttended} ${EN.event.USER_LIST.ATTENDED}`}
          </span>
        </div>
        {!permissions?.['ctaDisabled'] && (
          <span
            onClick={() => setShowAddAttendeeModal(!showAddAttendeeModal)}
            className={`text-Color_23 inline-block mb-6 cursor-pointer font-brandonMedium text-InputFontCommon ${
              noShow && 'pointer-events-none opacity-50'
            } ${
              calculateTimeDifference() &&
              (checkTodaysEvent(eventDate) || !checkFutureEvent(eventDate))
                ? ''
                : 'pointer-events-none opacity-50'
            }`}
          >
            {EN.event.USER_LIST.ADD_NEW_USER}
          </span>
        )}
        <div
          className={`user-list ${noShow && 'pointer-events-none opacity-50'} ${
            calculateTimeDifference() &&
            (checkTodaysEvent(eventDate) || !checkFutureEvent(eventDate))
              ? ''
              : 'pointer-events-none opacity-50'
          }`}
        >
          {usersList?.length > 0 &&
            usersList.map((item: EventOperationUsersList, index: number) => {
              return (
                <div className="py-4 pb-0" key={index}>
                  {!permissions?.['ctaDisabled'] && (
                    <input
                      type="checkbox"
                      className="checkbox pr-8 md:pr-4 cursor-pointer"
                      name={`checkbox_${index}`}
                      checked={item.attended}
                      onClick={() =>
                        markUserAttendance(item.tenantId, !item.attended)
                      }
                    />
                  )}
                  <div className="inline-grid relative bottom-1">
                    <PrimaryLabel
                      labelText={item.name}
                      className="text-InputFontCommon text-Color_22"
                    />
                    <PrimaryLabel labelText={item.email} />
                  </div>
                </div>
              );
            })}
          {usersList?.length > 0 &&
            !permissions?.['ctaDisabled'] &&
            calculateTimeDifference() &&
            (checkTodaysEvent(eventDate) || !checkFutureEvent(eventDate)) && (
              <div className="float-left pt-4 pb-4">
                <Button
                  label={EN.button.SAVE_ATTENDANCE}
                  className={`w-auto md:w-auto block ${
                    loading && 'button_disable'
                  }`}
                  backgroundColor="#34b2c3"
                  buttonText="#fefefe"
                  type={ButtonType.BUTTON}
                  onClick={marAttendance}
                  disabled={loading}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default UsersList;
