import React from 'react';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { getImageUrl } from '../../utils';
import { EN } from '../../constants/index';
import './Login.scss';

const EventAssignedMessage = () => {
  return (
    <div className="flex flex-col items-center px-5 pt-20 md:pt-32">
      <img
        src={getImageUrl('Events/event-not-available.png', true)}
        alt="logo"
        className="event-assigned-message"
      />
      <div className="pt-10">
        <PrimaryLabel
          labelText={EN.event.EVENT_ASSIGNED_MESSAGE}
          className="text-xl font-medium font-brandonMedium text-Color_22 text-center flex"
        />
      </div>
    </div>
  );
};

export default EventAssignedMessage;
