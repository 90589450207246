import moment from 'moment-timezone';
import ct from 'countries-and-timezones';
import { EventOperationTasksList } from '../models';
import {
  EVENT_DATE_FORMAT,
  EVENT__DATE_FILTER_FORMAT,
} from '../constants/index';

export const getImageUrl = (path: string, theme: boolean = false) => {
  // if it's theme asset then send theme = true else false
  if (theme) {
    return `${process.env.REACT_APP_CLOUDFRONT_URL}/${path}`;
  }
  return `${process.env.REACT_APP_MEDIA_URL}${path}`;
};

export const apiStatusCheck = (response) => {
  if (response?.['status'] === 200 || response?.['status'] === 201) {
    return true;
  } else {
    return false;
  }
};

export const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('formData', serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const loadState = (formName: string) => {
  try {
    const serializedState = localStorage.getItem('formData');
    if (!serializedState) {
      return {};
    }
    return JSON.parse(serializedState)?.['formData']?.[formName];
  } catch (error) {
    console.error(error);
  }
};

export const clearFormStorage = () => {
  try {
    localStorage.removeItem('formData');
  } catch (error) {
    console.error(error);
  }
};

export const findIndexFromArrayOfObject = (array, key, data) => {
  return array.findIndex((item) => item[key] === data[key]);
};

export const getUploadedImageUrl = (path: string) => {
  return `${process.env.REACT_APP_MEDIA_URL}${path}`;
};

export const splitImageName = (fileName: string) => {
  let uploadedImage;
  if (fileName) {
    uploadedImage = fileName.split('--hom-media--');
  }
  return uploadedImage[1];
};

let idArray = [];
export const getArrayOfIds = (id: number, emptyArray: boolean = false) => {
  const idIndex = idArray.indexOf(id);
  if (emptyArray) {
    idArray = [];
    return idArray;
  }
  if (idIndex !== -1) {
    idArray.splice(idIndex, 1);
    return idArray;
  }
  idArray.push(id);
  return idArray;
};

export const getDayAndTime = (
  date: string,
  dateFormat: string,
  timeZone?: string
) => {
  if (date) {
    const startEventTime = timeZone
      ? moment(date).tz(timeZone).format(dateFormat)
      : moment(date).format(dateFormat);
    return startEventTime;
  }
};

export const onTabChange = (tabName: string) => {
  switch (tabName) {
    case 'upcoming':
    case 'past':
      return 'upcoming';
    case 'open':
      return 'open';
    default:
      return 'upcoming';
  }
};

export const convertDateToEventDateFormat = (date: Date): string => {
  return moment(date).format(EVENT_DATE_FORMAT);
};

export const setQueryParameters = (parameter: string, key: string) => {
  const queryParams = new URLSearchParams(window.location.search);

  queryParams.set(key, parameter.toString());
  window.history.replaceState(null, null, '?' + queryParams.toString());
};

export const getQueryParameters = (key: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
};
export const isMobileCheck = () => {
  const isMobile = /iPhone|Android/i.test(navigator.userAgent);
  return isMobile;
};

export const checkPastEvent = (eventTime: string) => {
  return moment().utc().isSameOrAfter(eventTime);
};

export const checkLiveEvent = (eventTime: string) => {
  return moment().utc().isSameOrBefore(eventTime);
};

export const getVendorTaskFilter = (
  taskListArray: EventOperationTasksList[],
  assignCheck: boolean
) => {
  const taskList = taskListArray.filter(
    (item) => item.assignedToMe === assignCheck
  );
  return taskList;
};

export const convertDateToFilterDateFormat = (date: Date): string => {
  return moment(date).format(EVENT__DATE_FILTER_FORMAT).toLowerCase();
};

export const checkTodaysEvent = (date: string) => {
  return moment().utc().isSame(date, 'day');
};

export const getUserToken = () => {
  return localStorage.getItem('access');
};

export const checkFutureEvent = (futureDate: string) => {
  return moment(futureDate).isAfter(moment().utc());
};

export const getPastDates = (month: number) => {
  const currentDate = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const updatedDate =
    month !== currentMonth
      ? getFormatedDated(
          `${moment().year()}-${month < 10 ? `0${month}` : month}-01`
        )
      : getFormatedDated(
          `${moment().year()}-${month < 10 ? `0${month}` : month}-${
            currentDate > 1
              ? currentDate <= 10
                ? `0${currentDate - 1}`
                : currentDate - 1
              : currentDate
          }`
        );
  return updatedDate;
};

export const getFormatedDated = (date: string) => {
  return moment(date).format(EVENT_DATE_FORMAT);
};

export const replaceString = (
  content: string,
  contentValue: string,
  replacedValue: string
) => {
  return content.replace(contentValue, replacedValue);
};

export const getDateFormated = (date: string, format: string) => {
  return moment(date).format(format);
};

export const getIdFromYouTubeUrl = (url: string) => {
  let video_id = url.split('v=')[1];
  const ampersandPosition = video_id.indexOf('&');
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition);
  }
  return video_id;
};

export const linkString = (link: string) => {
  if (link.includes('http')) {
    return link;
  }
  return `https://${link}`;
};

export const getTimeZoneForCountry = (countryName: string) => {
  return ct.getTimezonesForCountry(countryName);
};

export const timeZones = () => {
  const timeZone = [
    ...getTimeZoneForCountry('US'),
    ...getTimeZoneForCountry('CA'),
    ...getTimeZoneForCountry('DE'),
  ];
  timeZone.forEach((item, i) => {
    item.id = i + 1;
  });
  return timeZone;
};

export const getTimeDifference = (eventTime: string, timezone: string) => {
  const difference =
    Number(moment(eventTime).tz(timezone).format('x')) -
    Number(moment().tz(timezone).format('x'));
  let hours;
  let minutes = 0;
  let seconds;
  let days;
  if (difference > 0) {
    days = Math.floor(difference / (1000 * 60 * 60 * 24));
    hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((difference / 1000 / 60) % 60);
    seconds = Math.floor((difference / 1000) % 60);
  }
  return {
    difference,
    hours,
    minutes,
    seconds,
    days,
  };
};
