import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EN } from '../../constants';
import { getUploadedImageUrl, splitImageName } from '../../utils';

const ImageClick: React.FC<{
  imageUrl: string;
  onDeleteImage: () => void;
}> = ({ imageUrl, onDeleteImage }) => {
  return (
    <>
      <a
        href={getUploadedImageUrl(imageUrl)}
        target="_blank"
        className="inline-block pr-3 text-Color_22"
        rel="noreferrer"
      >
        {splitImageName(imageUrl)}
      </a>
      <PrimaryLabel
        labelText={EN.profile.DELETE}
        className="cursor-pointer underline"
        onClick={onDeleteImage}
      />
    </>
  );
};

export default ImageClick;
