import * as modalTypes from './modal.constant';

export const openModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_MODAL,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.CLOSE_MODAL,
  });
};

export const openAssignModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_ASSIGN_MODAL,
  });
};

export const openMarkNoShowModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_MARK_NOSHOW,
  });
};

export const openSubRequestModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_SUB_REQUEST,
  });
};

export const openImagePreviewModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_IMAGE_PREVIEW,
  });
};
