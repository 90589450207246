import React from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../components/ReactModal/Modal';
import { EN } from '../constants';
import { ButtonType } from '../enums';

const ConfirmTimezoneModal: React.FC<{
  modalState: boolean;
  closeModal: (check: boolean) => void;
}> = ({ modalState, closeModal }) => {
  const onMarkNoShow = async () => {
    closeModal(true);
  };
  const onClose = async () => {
    closeModal(false);
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClose}
      className="p-8 md:p-12 pt-12 h-auto w-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 noshow-popup"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      <div className="grid grid-cols-1 gap-y-0">
        <PrimaryLabel
          labelText={EN.topNav.TIMEZONE_HEADING}
          className="font-medium text-lg col-span-2 md:text-SubHeaderFont mb-6 h-auto"
        />
        <PrimaryLabel
          labelText={`${EN.topNav.TIMEZONE_SUB_HEADING}`}
          className="block col-span-2 text-InputFontCommon mb-2"
        />
        <div className="col-span-2 mt-6">
          <Button
            label={EN.button.NO_CANCEL}
            className={`w-full md:w-auto block px-simple-button md:mr-2 ml-0 md:ml-16 mb-4 md:mb-0 float-left`}
            type={ButtonType.BUTTON}
            onClick={onClose}
          />
          <Button
            label={EN.button.CHANGE_TIME}
            className="w-auto block"
            backgroundColor="#f36b7f"
            buttonText="#fefefe"
            type={ButtonType.BUTTON}
            onClick={onMarkNoShow}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default ConfirmTimezoneModal;
