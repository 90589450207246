import { SlideDrawer, TenantTopnav } from '@alfredclub/hom-planner-theme';
import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IUserLoginResponse } from '../models';
import { openModal } from '../redux/modal/modal.action';
import PrivateRoute from './ProtectedRoute';
import EventListing from '../components/Events/EventListing';
import {
  tenantProfile,
  DATE_FORMAT,
  defaultTimezone,
  defaultFullTimezone,
  EN,
} from '../constants';
import { clearAuth } from '../redux/authentication';
import {
  clearEvents,
  IEventState,
  getTimeZone,
  getEventDateCount,
  getEvents,
} from '../redux/events';
import ContactUs from '../components/ContactUs/Contact';
import LoginForm from '../components/Authentication/LoginForm';
import {
  getImageUrl,
  getDateFormated,
  isMobileCheck,
  getQueryParameters,
  setQueryParameters,
  getPastDates,
  onTabChange,
} from '../utils';
import { INJURY_REPORT, CURRENT_DATE } from '../constants/index';
import EventDescription from '../components/Events/EventDescription';
import VendorChat from '../components/VendorChat/VendorChat';
import VendorSetting from '../components/Settings/VendorSetting';
import ResetPassword from '../components/Authentication/ResetPassword';
import ConfirmTimezoneModal from './ConfirmTimezoneModal';
import TimezoneModal from './TimezoneModal';
import { ItimeZone } from '../models';
import { useLocation } from 'react-router-dom';
import {
  getVendorDetail,
  IAccountsState,
  updateVendorSettings,
} from '../redux/user';
import { MenuAction } from '../enums';
import EventAssignedMessage from '../components/Authentication/EventAssignedMessage';

const Routes: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const isChatOpen = query.get('livestreamId');
  const callsheetPermissions = query.get('callsheet')
    ? JSON.parse(atob(query.get('callsheet')))
    : {};
  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  const { vendorDetail } = useSelector(
    (state: { user: IAccountsState }) => state.user
  );
  const { timezones } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = useState<ItimeZone>();
  const [openTimezoneModal, setOpenTimezoneModal] = useState<boolean>(false);
  const [showTimezoneConfirmation, setTimezoneConfirmation] = useState<boolean>(
    false
  );
  const monthSelected = new Date().getMonth() + 1;
  useEffect(() => {
    async function getVendor() {
      await dispatch(getVendorDetail());
    }
    if (access) {
      getVendor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);
  useEffect(() => {
    async function getEventData() {
      await dispatch(
        getEvents(
          getQueryParameters('tab') === EN.tabs.PAST.toLowerCase()
            ? getPastDates(monthSelected)
            : CURRENT_DATE,
          onTabChange(getQueryParameters('tab')),
          0,
          vendorDetail?.timezone?.id
            ? vendorDetail?.timezone?.zoneValue
            : defaultTimezone
        )
      );
      await dispatch(
        getEventDateCount(
          CURRENT_DATE,
          vendorDetail?.timezone?.id
            ? vendorDetail?.timezone?.zoneValue
            : defaultTimezone
        )
      );
      setQueryParameters(
        getQueryParameters('tab')
          ? getQueryParameters('tab')
          : EN.tabs.UPCOMING.toLowerCase(),
        'tab'
      );
    }
    if (access && isMobileCheck() && vendorDetail.hasOwnProperty('timezone')) {
      if (tenantProfile[0].id === 0) {
        tenantProfile.splice(0, 1);
      }
      tenantProfile.unshift({
        id: 0,
        label: `${
          vendorDetail?.timezone?.id
            ? vendorDetail?.timezone?.zoneValue
            : defaultTimezone
        } timezone`,
        imgSrc: 'Vendor/timezone-icon.svg',
      });
    } else if (access && vendorDetail.hasOwnProperty('timezone')) {
      getEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorDetail]);
  // const [zonLoading, setZoneLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const openLoginModal = () => {
    dispatch(openModal());
  };
  const selectTimeZone = (timeZone?: any) => {
    setSelectedTimezone(timeZone);
    setTimezoneConfirmation(true);
  };
  const onLogout = async () => {
    await dispatch(clearAuth());
    await dispatch(clearEvents());
  };

  const contactAdmin = () => {
    setDrawerState(!drawerState);
  };

  const timeZoneCheck = tenantProfile[0].id === 0 ? true : false;
  const onMenuClick = async (index: number) => {
    switch (index) {
      case 0:
        if (timeZoneCheck) {
          setOpenTimezoneModal(true);
        } else {
          // Do Nothing
        }
        break;
      case MenuAction.INJURY:
        window.open(INJURY_REPORT, '_blank');
        break;
      case MenuAction.SETTINGS:
        push('/setting');
        break;
      case MenuAction.CONTACT:
        contactAdmin();
        break;
      case MenuAction.LOGOUT:
        await onLogout();
        break;
    }
  };

  const modalClose = async (check: boolean) => {
    if (check) {
      vendorDetail.timezone = selectedTimezone;
      vendorDetail.birthday = getDateFormated(
        vendorDetail.birthday,
        DATE_FORMAT
      );
      await dispatch(updateVendorSettings(vendorDetail));
    }
    setTimezoneConfirmation(false);
  };

  const timeZoneModalClose = () => {
    setOpenTimezoneModal(false);
  };

  const routeMatch = useRouteMatch({
    path: '/event-assigned',
    strict: true,
  });

  return (
    <>
      {access && Object.keys(callsheetPermissions).length <= 0 && !routeMatch && (
        <div className="fixed top-0 w-full z-50">
          <TenantTopnav
            onLoginClick={openLoginModal}
            isLoggedIn={!access}
            profileOptions={tenantProfile}
            onMenuClick={(_, index: number) =>
              onMenuClick(isMobileCheck() ? index : index + 1)
            }
            onLogoClick={() => push('/?tab=upcoming')}
            isWhiteLabel={false}
            isVendor={true}
            homLogo={getImageUrl('Vendor/HOMVendorlogo.svg', true)}
            showRightSideIcons={!isChatOpen ? true : false}
            menuClass="w-mobileMenu md:w-64 top-9 md:top-8 h-94 md:h-auto p-4 md:p-0 md:pt-2 md:pb-2"
            menuRightClass="right-0 md:right-4"
            timeZoneOptions={timezones}
            selectedTimeZone={
              vendorDetail?.timezone?.id
                ? vendorDetail?.timezone?.fullName
                : defaultFullTimezone
            }
            onDropdownChange={(e) => selectTimeZone(e)}
            showTimeZone={
              !isChatOpen ? (!isMobileCheck() ? true : false) : false
            }
            timeZoneClass="relative pr-24"
            onTimeZoneMenuOpen={async () => {
              if (timezones?.length === 0) {
                await dispatch(getTimeZone());
              }
            }}
          />
        </div>
      )}
      <SlideDrawer
        show={drawerState}
        showCloseIcon={true}
        closePane={contactAdmin}
        showIconClass="p-8 md:p-0"
      >
        <ContactUs closePopup={contactAdmin} />
      </SlideDrawer>
      <Switch>
        <Route path="/login">
          <LoginForm />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <PrivateRoute path="/" exact={true}>
          <EventListing />
        </PrivateRoute>
        <PrivateRoute path="/detail/:eventId/" exact={true}>
          <EventDescription />
        </PrivateRoute>
        <Route path="/callsheet/:eventId/" exact={true}>
          <EventDescription />
        </Route>
        <PrivateRoute path="/setting" exact={true}>
          <VendorSetting />
        </PrivateRoute>
        <PrivateRoute path="/chat/:eventId/" exact={true}>
          <VendorChat />
        </PrivateRoute>
        <Route path="/event-assigned" exact={true}>
          <EventAssignedMessage />
        </Route>
      </Switch>
      {showTimezoneConfirmation && (
        <ConfirmTimezoneModal
          modalState={showTimezoneConfirmation}
          closeModal={modalClose}
        />
      )}
      <SlideDrawer
        show={openTimezoneModal}
        showCloseIcon={true}
        closePane={timeZoneModalClose}
        showIconClass="pl-6 pt-8"
      >
        <TimezoneModal timeZoneModalClose={timeZoneModalClose} />
      </SlideDrawer>
    </>
  );
};

export default Routes;
