import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { ILoaderState } from '../../redux/loader';
import ModalPopup from '../ReactModal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { EN } from '../../constants';
import { closeModal } from '../../redux/modal/modal.action';
import { ButtonType } from '../../enums';
import { markNoShow } from '../../redux/events';

const MarkNoShow: React.FC<{
  modalState: boolean;
  eventId: number;
}> = ({ modalState, eventId }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onMarkNoShow = async () => {
    await dispatch(markNoShow(eventId, { status: true }));
    onClose();
  };

  const onClose = async () => {
    await dispatch(closeModal());
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClose}
      className="p-8 md:p-12 pt-12 h-auto w-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 noshow-popup"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      <div className="grid grid-cols-1 gap-y-0">
        <PrimaryLabel
          labelText={EN.event.USER_LIST.N0_SHOW.TITLE}
          className="sub-header col-span-2 text-InputFontCommon md:text-SubHeaderFont mb-6 h-auto"
        />
        <PrimaryLabel
          labelText={`${EN.event.USER_LIST.N0_SHOW.PRIM_TITLE}`}
          className="block col-span-2 text-InputFontCommon mb-2"
        />
        <PrimaryLabel
          labelText={EN.event.USER_LIST.N0_SHOW.SEC_TITLE}
          className="block col-span-2 text-InputFontCommon"
        />
        <div className="col-span-2 mt-6">
          <Button
            label={EN.button.YES_MARK_NO}
            className={`w-full md:w-auto block px-simple-button md:mr-2 ml-0 md:ml-16 mb-4 md:mb-0 float-left ${
              loading && 'pointer-events-none'
            }`}
            type={ButtonType.BUTTON}
            onClick={onMarkNoShow}
          />
          <Button
            label={EN.button.NO_CANCEL}
            className="w-full md:w-32 block"
            backgroundColor="#f36b7f"
            buttonText="#fefefe"
            type={ButtonType.BUTTON}
            onClick={onClose}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default MarkNoShow;
