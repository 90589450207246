import React from 'react';
import ModalPopup from '../ReactModal/Modal';
import { getImageUrl } from '../../utils';

const ImagePreview: React.FC<{
  modalState: boolean;
  imgLink: string;
  closeModal: () => void;
}> = ({ modalState, imgLink, closeModal }) => {
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={closeModal}
      className="p-8 md:p-12 pt-10 w-auto h-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 floor-img"
      shouldCloseOnOverlayClick={false}
      closebtnClass="top-5 right-5"
    >
      <div className="">
        <img src={getImageUrl(imgLink)} alt="img" />
      </div>
    </ModalPopup>
  );
};

export default ImagePreview;
