import { http } from '../common/http.service';
import { AxiosResponse } from 'axios';
import {
  IContactAdmin,
  IContactAdminResponse,
  IUserDetailResponse,
  IUsersListResponse,
  IVendorDetail,
} from '../../models';

export const getVendorDetailApi = async (): Promise<IUserDetailResponse> => {
  return http
    .get(`/vendor/settings/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUserDetailResponse>) => {
      return response.data;
    });
};

export const updateVendorSettingsApi = async (
  data: IVendorDetail
): Promise<IUserDetailResponse> =>
  http
    .put(`/vendor/settings/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUserDetailResponse>) => {
      return response.data;
    });

export const contactAdminApi = async (
  data: IContactAdmin
): Promise<IContactAdminResponse> => {
  return http
    .post(`/contact-admin/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IContactAdminResponse>) => {
      return response.data;
    });
};

export const getSearchUsersApi = async (
  userName: string
): Promise<IUsersListResponse> => {
  return http
    .get(`search-user/?keyword=${userName}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IUsersListResponse>) => {
      return response.data;
    });
};
