import {
  ICommonMessageResponse,
  IEventCountResponse,
  ISendBroadcast,
  IMarkNoShow,
  ISubRequest,
  ISubRequestResponse,
} from './../../models/event';
import { http } from '../common/http.service';
import { AxiosResponse } from 'axios';
import {
  PaginatedResponse,
  IEvent,
  IEventDetailResponse,
  IViewNotesDetailResponse,
  IEventOperationDetailResponse,
  IMarkAttendanceResponse,
  EventOperationUsersList,
  IBuildingListResponse,
  IEmailCheckResponse,
  IRegisterUser,
  IRegisterUserResponse,
  Timezone,
} from '../../models';
import {
  CURRENT_DATE,
  PARAM,
  RECORDS_PAGE,
  RECORDS_LIMIT,
} from './../../constants';

export const getEventsApi = async (
  start_date: string = CURRENT_DATE,
  param: string = PARAM,
  page: number = RECORDS_PAGE,
  timeZone: string,
  limit: number = RECORDS_LIMIT
): Promise<PaginatedResponse<IEvent>> => {
  return http
    .get(
      `vendor-events/?start_date=${start_date}&param=${param}&limit=${limit}&page=${page}&regionTimezone=${timeZone}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<IEvent>>) => {
      return response.data;
    });
};

export const getFilterEventsApi = async (
  filter_date: string = CURRENT_DATE,
  param: string = PARAM,
  timeZone: string,
  page: number = RECORDS_PAGE,
  limit: number = RECORDS_LIMIT
): Promise<PaginatedResponse<IEvent>> => {
  return http
    .get(
      `vendor-events/?start_date=${filter_date}&page=${page}&param=${param}&limit=${limit}&filter_type=1&regionTimezone=${timeZone}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<IEvent>>) => {
      return response.data;
    });
};

export const getEventDetailApi = async (
  eventId: number,
  elementId?: number
): Promise<IEventDetailResponse> => {
  return http
    .get(`/vendor-events/${eventId}/?elementId=${elementId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IEventDetailResponse>) => {
      return response.data;
    });
};

export const eventDateCountApi = (
  start_date: string,
  timeZone?: string
): Promise<IEventCountResponse> => {
  return http
    .get(
      `/vendor-events-count/?start_date=${start_date}&regionTimezone=${timeZone}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<IEventCountResponse>) => {
      return response.data;
    });
};

export const getViewNotesDetailApi = async (
  eventId: number
): Promise<IViewNotesDetailResponse> => {
  return http
    .get(`/event-details/${eventId}/?notes=1`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IViewNotesDetailResponse>) => {
      return response.data;
    });
};

export const assignOpenEventApi = async (
  eventId: number,
  inviteId?: number,
  elementId?: number
): Promise<ICommonMessageResponse> => {
  const inviteObj = {
    inviteId,
    elementId,
  };
  return http
    .post(`/vendor-events/${eventId}/?elementId=${elementId}`, inviteObj, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<ICommonMessageResponse>) => {
      return response.data;
    });
};

export const getEventOperationDetailApi = async (
  eventId: number,
  elementId: number
): Promise<IEventOperationDetailResponse> => {
  return http
    .get(`/event-details/${eventId}/?elementId=${elementId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IEventOperationDetailResponse>) => {
      return response.data;
    });
};

export const markAttendanceApi = async (
  eventId: number,
  markAttendance: EventOperationUsersList[]
): Promise<IMarkAttendanceResponse> => {
  const attendanceObj = {
    attendees: markAttendance,
  };
  return http
    .post(`/mark-attendance/${eventId}/`, attendanceObj, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IMarkAttendanceResponse>) => {
      return response.data;
    });
};

export const markNoShowApi = async (
  eventId: number,
  data: IMarkNoShow
): Promise<ICommonMessageResponse> => {
  return http
    .post(`/event-details/${eventId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ICommonMessageResponse>) => {
      return response.data;
    });
};

export const sendSubRequestApi = async (
  eventId: number,
  requestData: ISubRequest,
  elementId: number
): Promise<ISubRequestResponse> => {
  return http
    .post(`/sub-request/${eventId}/?elementId=${elementId}`, requestData, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ISubRequestResponse>) => {
      return response.data;
    });
};

export const sendBroadcastMessageApi = async (
  eventId: number,
  requestData: ISendBroadcast
): Promise<ICommonMessageResponse> => {
  return http
    .post(`/broadcast/${eventId}/`, requestData, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ICommonMessageResponse>) => {
      return response.data;
    });
};

export const getBuildingsListApi = async (): Promise<IBuildingListResponse> => {
  return http
    .get(`/property/buildings/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IBuildingListResponse>) => {
      return response.data;
    });
};

export const getEmailCheckApi = async (
  email: string
): Promise<IEmailCheckResponse> => {
  return http
    .get(`/tenant/check-email/?email=${email}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IEmailCheckResponse>) => {
      return response.data;
    });
};

export const registerNewUserApi = async (
  userObj: IRegisterUser,
  eventId: number
): Promise<IRegisterUserResponse> => {
  return http
    .post(`/mark-attendance/${eventId}/?new_user=1`, userObj, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IRegisterUserResponse>) => {
      return response.data;
    });
};

export const markSingleUserAttendanceApi = async (
  eventId: number,
  markAttendance: EventOperationUsersList
): Promise<IMarkAttendanceResponse> => {
  return http
    .post(`/mark-attendance/${eventId}/?single_attendance=1`, markAttendance, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IMarkAttendanceResponse>) => {
      return response.data;
    });
};

export const assignScheduleEventApi = async (
  scheduleId: number,
  weekday: number
): Promise<ICommonMessageResponse> => {
  const inviteObj = {
    weekday,
  };
  return http
    .post(`/schedule-invite/${scheduleId}/`, inviteObj, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<ICommonMessageResponse>) => {
      return response.data;
    });
};

export const assignScheduleEventStatusApi = async (
  scheduleId: number,
  weekday: number
): Promise<ICommonMessageResponse> => {
  return http
    .get(`/schedule-invite/${scheduleId}/?weekday=${weekday}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ICommonMessageResponse>) => {
      return response.data;
    });
};

export const assignSubRequestApi = async (
  subrequestId: number,
  elementId?: number | null
): Promise<ICommonMessageResponse> => {
  const elementObj = {
    elementId,
  };
  return http
    .put(`/sub-request/${subrequestId}/`, elementObj, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ICommonMessageResponse>) => {
      return response.data;
    });
};

export const getTimeZoneApi = async (): Promise<Timezone> => {
  return http
    .get(`/base/zones/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Timezone>) => {
      return response.data;
    });
};
