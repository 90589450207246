import React from 'react';
import Chat from '../Common/TwilioChat';
import EventDescription from '../Events/EventDescription';
import './VendorChat.scss';
import { getQueryParameters, getUserToken } from '../../utils';
import { EN } from '../../constants';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useParams } from 'react-router-dom';

const VendorChat = () => {
  const params = useParams();
  const eventId = parseInt(params['eventId'], 10);
  return (
    <div className="container md:container lg:container md:mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0 vendor-chat">
      <EventDescription
        showLiveStreamButtons={false}
        showEventAction={false}
        showTabs={false}
        showMobileMessage={false}
        isVendorChat={true}
      />
      <div className="m-4 col-span-2">
        <div className="text-right mb-2">
          <PrimaryLabel
            labelText={EN.vendorChat.REFRESH}
            className="text-F16"
          />
        </div>

        <Chat
          channelName={`${getQueryParameters(
            'livestreamId'
          )}${eventId.toString()}`}
          btnText={EN.vendorChat.BTN_SEND_MESSAGE}
          teacher={true}
          userToken={getUserToken()}
          sectionStyleClass="teacherChat w-auto md:w-full"
        />
      </div>
    </div>
  );
};

export default VendorChat;
