export enum EventAction {
  OPENMAP = 1,
  VIEWNOTES = 2,
  BROADCAST = 3,
  SUBREQUEST = 4,
}

export enum MenuAction {
  INJURY = 1,
  SETTINGS = 2,
  CONTACT = 3,
  LOGOUT = 4,
}
