import { loadState } from './../utils/index';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useDebounce = (delay) => {
  const debounced = useDebouncedCallback(
    // function
    (action, searchTerm) => {
      action(searchTerm);
    },
    // delay in ms
    delay
  );
  return { debounce: debounced.callback };
};

export const useFormDebounce = (fn, delay, values, clearFn = null) => {
  const dispatch = useDispatch();
  const timer = useRef(null);
  const handleOnChange = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      (async () => {
        await dispatch(fn(values()));
      })();
    }, delay);
  };
  useEffect(() => {
    window.addEventListener('change', handleOnChange, false);
    return () => {
      window.removeEventListener('change', handleOnChange, false);
      clearTimeout(timer.current);
      (async () => {
        if (clearFn) {
          await dispatch(clearFn());
        }
      })();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useAutoSave = (fn, delay, values, updateFn, formName, id) => {
  const dispatch = useDispatch();
  const timer = useRef(null);
  const handleOnChange = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      (async () => {
        await dispatch(fn(values()));
        await dispatch(updateFn(id, loadState(formName)));
      })();
    }, delay);
  };
  useEffect(() => {
    window.addEventListener('change', handleOnChange, false);
    return () => {
      window.removeEventListener('change', handleOnChange, false);
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
