import React, { useRef, useEffect, useState } from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { IResetPasswordDetails } from '../../models';
import { getImageUrl, getQueryParameters } from '../../utils';
import { useDispatch } from 'react-redux';
import {
  resetPassword,
  verifyUserToken,
  IUserLoginState,
} from '../../redux/authentication';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader';
import { EN } from '../../constants';
import CommonLoader from '../Common/CommonLoader';
import ErrorMessage from './ErrorMessage';
import './Login.scss';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const token = getQueryParameters('reset-token');
  const email = getQueryParameters('email');
  const [resetLoader, setResetLoader] = useState<boolean>(true);
  useEffect(() => {
    async function getVerifyToken() {
      const verifyTokenObj = {
        token,
        email,
      };
      setResetLoader(true);
      await dispatch(verifyUserToken(verifyTokenObj));
      setResetLoader(false);
    }
    getVerifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { verifyToken } = useSelector(
    (state: { authentication: IUserLoginState }) => state.authentication
  );
  const { register, errors, handleSubmit, watch } = useForm<
    IResetPasswordDetails
  >();
  const password = useRef({});
  password.current = watch('password', '');
  const onSubmit = async (data: IResetPasswordDetails) => {
    delete data.newPassword;
    data['token'] = token;
    data['email'] = email;
    await dispatch(resetPassword(data));
    push('/');
  };
  const isError = verifyToken?.['error']?.['code'] ? true : false;
  return (
    <>
      {resetLoader ? (
        <CommonLoader loading={resetLoader} />
      ) : isError ? (
        <ErrorMessage message={verifyToken?.error?.message} />
      ) : (
        <div className="login_wrapper">
          <div className="rightside">
            <form onSubmit={handleSubmit(onSubmit)}>
              <img
                src={getImageUrl('Vendor/HOMVendorlogo.svg', true)}
                alt="logo"
              />
              <div className="mt-8">
                <PrimaryLabel
                  labelText={EN.login.ENTER_NEW_PASSWORD}
                  className="text-Color_22 font-brandonBold forgot_heading "
                />
              </div>
              <div className="pt-4 mt-4">
                <PrimaryLabel
                  labelText={EN.login.ENTER_PASSWORD}
                  className="field_label"
                />
                <Input
                  label=""
                  placeholder=""
                  name="password"
                  inputType="password"
                  ref={register({
                    required: true,
                  })}
                  isError={errors.password ? true : false}
                />
              </div>
              <div className="pt-4 mt-4">
                <PrimaryLabel
                  labelText={EN.login.RE_ENTER_PASSWORD}
                  className="field_label"
                />
                <Input
                  label=""
                  placeholder=""
                  name="newPassword"
                  inputType="password"
                  ref={register({
                    required: true,
                    validate: (value) =>
                      value === password.current ||
                      EN.login.PASSWORD_NOT_MATCHED,
                  })}
                  isError={errors.newPassword ? true : false}
                />
                {errors.newPassword && (
                  <PrimaryLabel
                    labelText={errors.newPassword.message}
                    className="text-Color_42"
                  />
                )}
              </div>
              <div className="mt-12">
                <Button
                  label={EN.login.CREATE_PASSWORD}
                  className="login_btn"
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
