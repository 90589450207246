import React, { useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  PrimaryLabel,
  Dropdown,
  Input,
} from '@alfredclub/hom-planner-theme';
import { ILoaderState } from '../../../redux/loader';
import ModalPopup from '../../ReactModal/Modal';
import { useDebounce } from '../../../hooks';
import { IEventState } from '../../../redux/events';
import {
  getBuildingsList,
  isEmailExists,
  registerNewUser,
  clearMarkAttendance,
} from '../../../redux/events';
import { EN, MONTH_DATE_FORMAT } from '../../../constants';
import { ButtonType } from '../../../enums';
import { IRegisterUser } from '../../../models';
import { getImageUrl, replaceString } from '../../../utils';
import './EventAttendees.scss';
import '../EventListing.scss';

const RegisterAttendee: React.FC<{
  modalState: boolean;
  eventId: number;
  closeModal: (check: boolean) => void;
  elementId: number;
}> = ({ modalState, eventId, closeModal, elementId }) => {
  const dispatch = useDispatch();
  const { debounce } = useDebounce(1000);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { buildingList } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { emailExist } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { markUserAttenDance } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const [buildingLoading, setBuildingLoading] = useState<boolean>(false);
  const [showUser, setUser] = useState<string>('');
  const { handleSubmit, register, errors, setValue } = useForm<IRegisterUser>();

  const onSubmit = async (data: IRegisterUser) => {
    const userName = `${data.firstName} ${data.lastName}`;
    setUser(userName);
    data['status'] = true;
    await dispatch(registerNewUser(data, eventId, elementId));
  };
  const emailExistCheck = async (value: string) => {
    await dispatch(isEmailExists(value));
  };
  const emailCheck = () => {
    return emailExist ?? errors['email'] ? true : false;
  };
  const currentDate = moment().format(MONTH_DATE_FORMAT);
  const replacedUserName = replaceString(
    EN.event.USER_REGISTERED,
    '{{userName}}',
    showUser
  );
  const openAttendeeModal = () => {
    dispatch(clearMarkAttendance());
    closeModal(true);
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={() => closeModal(false)}
      className="p-8 md:p-12 pt-12 w-auto h-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 attendee-popup"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      {markUserAttenDance?.message ? (
        <div className="flex flex-col items-center">
          <img
            src={getImageUrl('Vendor/Done.svg', true)}
            className="pr-6"
            alt="Done"
          />
          <PrimaryLabel
            className="text-lg font-medium text-Color_22 mt-2"
            labelText={replaceString(replacedUserName, '{{Date}}', currentDate)}
          />
          <div className="pt-6">
            <Button
              label={EN.event.ADD_ANOTHER_ATTENDEE}
              className={`block px-simple-button`}
              type={ButtonType.BUTTON}
              onClick={openAttendeeModal}
            />
          </div>
        </div>
      ) : (
        <>
          <PrimaryLabel
            className="text-xl font-medium text-Color_22"
            labelText={EN.event.USER_LIST.REGISTER_NEW_USER}
          />
          <div className="mt-5">
            <form className="w-full mt-7" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <PrimaryLabel labelText={EN.event.EMAIL_ADDRESS} />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  label=""
                  name="email"
                  inputType="text"
                  onInputChange={(e) =>
                    debounce(emailExistCheck, e.target.value)
                  }
                  isError={emailCheck()}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: EN.event.INVALID_EMAIL,
                    },
                  })}
                />
                {emailExist && (
                  <div className="flex flex-row">
                    <img
                      src={getImageUrl('Vendor/alertred.svg', true)}
                      alt="Alertred"
                    />
                    <span className="ml-1 font-medium text-base user-not-registered">
                      {EN.event.EMAIL_EXIST}
                    </span>
                  </div>
                )}
              </div>
              <div className="mt-5">
                <PrimaryLabel labelText={EN.event.FIRST_NAME} />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  label=""
                  name="firstName"
                  inputType="text"
                  ref={register({ required: true })}
                  isError={errors?.firstName ? true : false}
                />
              </div>
              <div className="mt-5">
                <PrimaryLabel labelText={EN.event.LAST_NAME} />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  label=""
                  name="lastName"
                  inputType="text"
                  ref={register({ required: true })}
                  isError={errors['lastName'] ? true : false}
                />
              </div>
              <div className="mt-5">
                <PrimaryLabel labelText={EN.event.SELECT_BUILDING} />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Dropdown
                  asyncDropdown={false}
                  labelKey="name"
                  valueKey="id"
                  isLabelShow={false}
                  label=""
                  options={buildingList}
                  onMenuOpen={async () => {
                    setBuildingLoading(true);
                    if (buildingList.length === 0) {
                      await dispatch(getBuildingsList());
                    }
                    setBuildingLoading(false);
                  }}
                  loading={buildingLoading}
                  ref={() =>
                    register({ name: 'buildingId' }, { required: true })
                  }
                  onOptionChange={(e) => {
                    setValue('buildingId', e['id']);
                  }}
                  placeholder={EN.event.SELECT_BUILDING}
                  ddlClass="mb-0"
                  isError={errors['buildingId'] ? true : false}
                />
              </div>
              <div className="mt-8 pb-10">
                <Button
                  label={EN.event.ADD_USER}
                  className={`block px-simple-button ${
                    loading && 'button_disable'
                  }`}
                  type={ButtonType.SUBMIT}
                  backgroundColor="#f36b7f"
                  buttonText="#fefefe"
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </>
      )}
    </ModalPopup>
  );
};

export default RegisterAttendee;
