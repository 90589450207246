import {
  loginApi,
  forgotPasswordApi,
  resetPasswordApi,
  verifyUserTokenApi,
} from './authentication.service';
import * as authTypes from './authentication.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import {
  IUserLogin,
  IForgotPasswordDetails,
  IResetPasswordDetails,
  IVerifyTokenDetails,
} from '../../models/user';
import { TOAST_MESSAGES } from '../../constants';

export const login = (data: IUserLogin) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const authResponse = await loginApi(data);
  if (authResponse?.['data']) {
    const access = authResponse?.['data']?.['access'];
    const accessObj = access && JSON.parse(atob(access.split('.')[1]));
    if (accessObj?.['is_vendor']) {
      dispatch({
        type: authTypes.AUTH_LOGIN,
        payload: authResponse?.['data'],
      });
    } else {
      dispatch({
        type: toastTypes.ERROR_TOAST,
        toastMessage: TOAST_MESSAGES.login.noAccess,
      });
    }
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearAuth = () => async (dispatch) => {
  await localStorage.clear();
  dispatch({
    type: authTypes.CLEAR_TOKEN,
  });
};

export const refreshToken = (data) => {
  return {
    type: authTypes.UPDATE_REFRESH_TOKEN,
    payload: data,
  };
};

export const forgotPassword = (data: IForgotPasswordDetails) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response = await forgotPasswordApi(data);
  if (response?.['data']) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: response.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const resetPassword = (data: IResetPasswordDetails) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response = await resetPasswordApi(data);
  if (response?.['data']) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: response.data.message,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const verifyUserToken = (data: IVerifyTokenDetails) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const response = await verifyUserTokenApi(data);
  if (response?.['data']) {
    dispatch({
      type: authTypes.VERIFY_TOKEN,
      payload: response,
    });
  } else if (response?.['error']?.['code'] === 400) {
    dispatch({
      type: authTypes.VERIFY_TOKEN,
      payload: response,
    });
  } else {
    dispatch({
      type: authTypes.VERIFY_TOKEN,
      payload: response,
    });
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: response?.['error']?.['message'],
    });
  }
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const updateTimeZone = (timezone?: string) => {
  return {
    type: authTypes.UPDATE_TIMEZONE,
    payload: timezone ?? localStorage.getItem('userTimezone') ?? '',
  };
};
