import { Button, PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { useForm } from 'react-hook-form';
import { EN } from '../../constants';
import { sendBroadcastMessage } from '../../redux/events';
import { useDispatch } from 'react-redux';
import { ISendBroadcast } from '../../models';

const SendBroadcast: React.FC<{ eventId: number; closePopup: () => void }> = ({
  eventId,
  closePopup,
}) => {
  const { register, handleSubmit, errors } = useForm<ISendBroadcast>();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    await dispatch(sendBroadcastMessage(eventId, data));
    closePopup();
  };

  return (
    <div className="broadcast-section p-12 pt-0 md:pt-12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PrimaryLabel
          labelText={EN.event.SEND_BROADCAST.TITLE}
          className="sub-header text-F20 md:text-F25 text-Color_22 block mb-4 font-brandonBold"
        />
        <PrimaryLabel
          labelText={EN.event.SEND_BROADCAST.SUB_TITLE}
          className="mb-4 text-MobileFont"
        />
        <TextArea
          label=""
          name="message"
          placeholder={EN.event.SEND_BROADCAST.PLACEHOLDER}
          ref={register({ required: true })}
          className="broadcast-textarea mt-4 mb-4"
        />
        <div className="block">
          {Object.keys(errors).length > 0 && (
            <PrimaryLabel
              labelText={EN.event.SEND_BROADCAST.ERROR_MSG}
              className="text-red-600 md:pl-8"
            />
          )}
          <Button
            label={EN.event.SEND_BROADCAST.BTN_SEND_MESSAGE}
            isArrowShow={true}
            className="float-left broadcast-button text-SubHeaderFont font-brandonMedium"
            backgroundColor="#f36b7f"
            buttonText="#fefefe"
          />
        </div>
      </form>
    </div>
  );
};

export default SendBroadcast;
