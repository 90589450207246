import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import './Profile.scss';
import { Register, SetValue } from '../../models/commonType';
import { EN } from '../../constants';
import { IVendorDetail } from '../../models';

const BasicDetails: React.FC<{
  register: Register;
  setValue: SetValue;
  vendorDetail: IVendorDetail;
}> = ({ register, setValue, vendorDetail }) => {
  return (
    <>
      <div>
        <Button label={EN.profile.BTN_SAVE} backgroundColor="#f36b7f" />
        <PrimaryLabel
          labelText={EN.profile.TITLE}
          className="sub-header header-title"
        />
      </div>
      <div className="mt-16">
        <PrimaryLabel
          labelText={EN.profile.BASIC_DETAIL}
          className="sub-header font-brandonMedium text-F20"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-x-8 mt-4">
          <div>
            <PrimaryLabel labelText={EN.profile.BasicDetail.F_NAME} />
            <Input
              label={EN.profile.BasicDetail.F_NAME}
              name="firstName"
              ref={register}
            />
          </div>
          <div>
            <PrimaryLabel labelText={EN.profile.BasicDetail.L_NAME} />
            <Input
              label={EN.profile.BasicDetail.L_NAME}
              name="lastName"
              ref={register}
            />
          </div>
          <div>
            <PrimaryLabel labelText={EN.profile.BasicDetail.EMAIL} />
            <Input
              label={EN.profile.BasicDetail.EMAIL}
              name="email"
              inputType="email"
              ref={register}
              className="pointer-events-none bg-Color_35"
            />
          </div>
          <div>
            <PrimaryLabel labelText={EN.profile.BasicDetail.PHONE} />
            <Input
              label={EN.profile.BasicDetail.PHONE}
              name="cellPhone"
              ref={register({
                required: false,
                pattern: {
                  value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                  message: 'Invalid phone number',
                },
              })}
            />
          </div>
          <div>
            <PrimaryLabel labelText={EN.profile.BasicDetail.BIRTHDAY} />
            <Input
              isLabelShow={false}
              label=""
              ref={register({ name: 'birthday' })}
              onDateChange={(date) => setValue('birthday', date)}
              showDatepicker={true}
              placeholder=""
              selectedDate={
                vendorDetail?.['birthday']
                  ? new Date(vendorDetail?.['birthday'])
                  : new Date()
              }
              labelClass=""
            />
          </div>
          <div>
            <PrimaryLabel labelText={EN.profile.BasicDetail.BUSINESS_PHONE} />
            <Input
              label={EN.profile.BasicDetail.BUSINESS_PHONE}
              name="businessPhone"
              ref={register({
                required: false,
                pattern: {
                  value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                  message: 'Invalid business phone number',
                },
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
