import { IUsersList, IVendorDetail } from '../../models';

export interface IAccountsState {
  vendorDetail: IVendorDetail;
  usersList: IUsersList[];
  usersNotFound: boolean;
}

export const initialAccountState: IAccountsState = {
  vendorDetail: {},
  usersList: [],
  usersNotFound: false,
};
