import React, { useState, useEffect } from 'react';
import { PrimaryLabel, Tabs } from '@alfredclub/hom-planner-theme';
import { useSelector } from 'react-redux';
import { IEventState } from '../../redux/events';
import { EN } from '../../constants';
import './EventListing.scss';
import { getImageUrl } from '../../utils';
import ImagePreview from '../Common/ImagePreview';
import { IModalState } from '../../redux/modal/modal.state';
import {
  closeModal,
  openImagePreviewModal,
} from '../../redux/modal/modal.action';
import { useDispatch } from 'react-redux';

const CommonTypes: React.FC<{
  label: string;
  value: string;
  isAnchor?: boolean;
}> = ({ label, value, isAnchor }) => {
  return (
    <>
      <PrimaryLabel
        labelText={label}
        className="block h-auto notes-title mb-4 pt-8"
      />
      {!isAnchor ? (
        <PrimaryLabel
          labelText={value}
          className="sub-header block h-auto admin-notes-text font-medium"
        />
      ) : value !== EN.viewNotes.NO_DETAIL ? (
        <a href={value} target="_blank" className="underline" rel="noreferrer">
          {value}
        </a>
      ) : (
        EN.viewNotes.NO_DETAIL
      )}
    </>
  );
};

const ViewNotesDetail: React.FC<{ isOnline?: boolean }> = ({ isOnline }) => {
  const dispatch = useDispatch();

  const { viewNotesDetail } = useSelector(
    (state: { events: IEventState }) => state.events
  );
  const { openImgPreview } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const [tab, setTab] = useState<string>(isOnline ? 'admin' : 'info');
  const [floorImg, setFloorImage] = useState<string | null>(null);

  useEffect(() => {
    setTab(isOnline ? 'admin' : 'info');
  }, [isOnline]);

  const NotesDataCheck = (value: string) => {
    return value === '' || value === 'N/A' ? EN.viewNotes.NO_DETAIL : value;
  };

  const onFloorPlanClick = async (imgSrc: string) => {
    setFloorImage(imgSrc);
    await dispatch(openImagePreviewModal());
  };

  const close = async () => {
    await dispatch(closeModal());
    setFloorImage(null);
  };

  return (
    <>
      {openImgPreview && (
        <ImagePreview
          modalState={openImgPreview}
          imgLink={floorImg}
          closeModal={close}
        />
      )}
      <div className="mt-8 admin-notes-section">
        <Tabs
          className="mt-6"
          onTabClick={async (tabName: string) => {
            setTab(tabName);
          }}
          buttonColor="#f36b7f"
          imgClass="hidden"
          isWhiteLabel={false}
          selectedTab={tab}
          tabClassName="notes-tab"
          activeTabClass="text-Color_34"
        >
          <div data-label={EN.viewNotes.INFO} data-click={true}>
            <CommonTypes
              label={EN.viewNotes.FRONT_DESK_NUMBER}
              value={NotesDataCheck(viewNotesDetail?.info?.frontDeskNumber)}
            />
            <CommonTypes
              label={EN.viewNotes.STREET_ADDRESS}
              value={NotesDataCheck(viewNotesDetail?.info?.streetAddress)}
            />
            <CommonTypes
              label={EN.viewNotes.STUDIO_LOCATION}
              value={NotesDataCheck(viewNotesDetail?.info?.studioLocation)}
            />
            <CommonTypes
              label={EN.viewNotes.BATHROOM_ACCESS}
              value={NotesDataCheck(viewNotesDetail?.info?.bathroomAccess)}
            />

            <CommonTypes
              label={EN.viewNotes.SPACE_VIDEO}
              value={NotesDataCheck(viewNotesDetail?.info?.videoUrl)}
              isAnchor={true}
            />

            <CommonTypes
              label={EN.viewNotes.WATER}
              value={NotesDataCheck(viewNotesDetail?.info?.water)}
            />
            <CommonTypes
              label={EN.viewNotes.GARBAGE}
              value={NotesDataCheck(viewNotesDetail?.info?.garbageArea)}
            />
            <CommonTypes
              label={EN.viewNotes.OUTLETS}
              value={NotesDataCheck(viewNotesDetail?.info?.outlets)}
            />
            <CommonTypes
              label={EN.viewNotes.PARKING_DETAILS}
              value={NotesDataCheck(viewNotesDetail?.info?.parkingDetails)}
            />
            <PrimaryLabel
              labelText={EN.viewNotes.FLOOR_PLAN}
              className="block h-auto notes-title mb-4 pt-8"
            />
            {viewNotesDetail?.info?.floorPlan ? (
              <img
                src={getImageUrl(viewNotesDetail?.info?.floorPlan)}
                alt="floor-plan"
                className="mb-16 w-32 h-32 cursor-pointer"
                onClick={() =>
                  onFloorPlanClick(viewNotesDetail?.info?.floorPlan)
                }
              />
            ) : (
              <PrimaryLabel
                labelText={EN.viewNotes.NO_DETAIL}
                className="pb-16 md:pb-0 admin-notes-text font-medium"
              />
            )}
          </div>
          <div data-label={EN.viewNotes.ADMIN} data-click={true}>
            <CommonTypes
              label={EN.viewNotes.ADMIN_NOTES}
              value={NotesDataCheck(viewNotesDetail?.adminNotes)}
            />
          </div>
          <div data-label={EN.viewNotes.SECURITY} data-click={true}>
            <CommonTypes
              label={EN.viewNotes.SECURITY_ACCESS}
              value={NotesDataCheck(viewNotesDetail?.security?.securityAccess)}
            />
            <CommonTypes
              label={EN.viewNotes.TRUNK_CODES}
              value={NotesDataCheck(viewNotesDetail?.security?.trunkCode)}
            />
          </div>
          <div data-label={EN.viewNotes.WIFI} data-click={true}>
            <CommonTypes
              label={EN.viewNotes.WIFI_NAME_PASSWORD}
              value={NotesDataCheck(viewNotesDetail?.wifi)}
            />
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default ViewNotesDetail;
