import { Button, PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import React, { useState } from 'react';
import { ILoaderState } from '../../redux/loader';
import ModalPopup from '../ReactModal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { EN, subRequestReason } from '../../constants';
import { closeModal } from '../../redux/modal/modal.action';
import { useForm } from 'react-hook-form';
import { ISubRequest, TinyObject } from '../../models';
import { sendSubRequest } from '../../redux/events';

const SubRequest: React.FC<{
  modalState: boolean;
  eventId: number;
  elementId: number;
}> = ({ modalState, eventId, elementId }) => {
  const dispatch = useDispatch();
  const [checked, setCheckedReason] = useState<TinyObject>({});
  const { register, handleSubmit, errors } = useForm();

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onClose = async () => {
    await dispatch(closeModal());
  };

  const onSubmit = async (data: ISubRequest) => {
    const dataObj = {
      reason: checked?.['name'],
      reasonDescription: data.reasonDescription,
      elementId,
    };
    await dispatch(sendSubRequest(eventId, dataObj, elementId));
    onClose();
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClose}
      className="p-8 md:p-12 pt-12 h-auto w-auto md:w-eventCardWidth rounded-lg transform translate-y-8 md:translate-y-24 sub-request-wrapper"
      shouldCloseOnOverlayClick={false}
      closebtnClass="top-6 right-6"
    >
      <div className="grid grid-cols-1 gap-y-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <PrimaryLabel
            labelText={EN.event.SUB_REQUEST.TITLE}
            className="block sub-header col-span-2 text-EventHeader md:text-HeaderFont mb-6 h-auto"
          />
          <PrimaryLabel
            labelText={EN.event.SUB_REQUEST.SUB_TITLE}
            className="block col-span-2 text-SubHeaderFont mb-6"
          />
          {subRequestReason?.map((item: TinyObject, index: number) => {
            return (
              <div className="block col-span-2" key={index}>
                <input
                  type="checkbox"
                  className="radio pr-4 cursor-pointer"
                  name="checkbox"
                  onChange={() => setCheckedReason(item)}
                  checked={checked?.['id'] === item.id}
                  ref={register({ required: true })}
                />
                <PrimaryLabel
                  labelText={item.name}
                  className="text-InputFontCommon text-Color_22 align-top"
                />
              </div>
            );
          })}
          <div className="pt-4">
            <PrimaryLabel labelText={EN.event.SUB_REQUEST.REQUEST_REASON} />
            <TextArea
              label=""
              name="reasonDescription"
              placeholder={EN.event.SUB_REQUEST.PLACEHOLDER}
              ref={register}
              className="mb-4"
            />
          </div>
          <div className="block">
            {Object.keys(errors).length > 0 && (
              <PrimaryLabel
                labelText={EN.event.SUB_REQUEST.ERROR_MSG}
                className="text-red-600"
              />
            )}
            <Button
              label={EN.event.SUB_REQUEST.BTN_SEND}
              className={`w-full md:w-48 block ${
                loading && 'pointer-events-none'
              }`}
              backgroundColor="#f36b7f"
              buttonText="#fefefe"
            />
          </div>
        </form>
      </div>
    </ModalPopup>
  );
};

export default SubRequest;
