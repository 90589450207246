import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EN } from '../../constants';
import ModalPopup from '../ReactModal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { ILoaderState } from '../../redux/loader';
import { ButtonType } from '../../enums';
import { closeModal } from '../../redux/modal/modal.action';
import {
  assignOpenEvent,
  assignScheduleEvent,
  assignSubRequest,
} from '../../redux/events';
import { useHistory } from 'react-router-dom';
import Config from '../../config/environment';

const SelfAssignPopup: React.FC<{
  modalState: boolean;
  eventId: number;
  inviteId?: number;
  scheduleId?: number;
  weekday?: number;
  subrequestId?: number;
  elementId?: number;
  fromDesc?: boolean;
}> = ({
  modalState,
  eventId,
  inviteId,
  scheduleId,
  weekday,
  subrequestId,
  elementId,
  fromDesc,
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onAssignOpenEvent = async () => {
    // If case is for assign schedule events from email
    if (scheduleId) {
      const response = await dispatch(assignScheduleEvent(scheduleId, weekday));
      if (response) {
        if (window.location.hostname === 'localhost') {
          window.location.href = `http://localhost:3000/?tab=upcoming`;
        } else {
          window.location.href = `${Config.VENDOR_URL}/?tab=upcoming`;
        }
      }
    } else if (subrequestId) {
      // If case is for assign events from subrequest email
      await dispatch(assignSubRequest(subrequestId, elementId));
    } else {
      await dispatch(assignOpenEvent(eventId, inviteId, elementId));
    }
    close();
    if (inviteId || subrequestId || fromDesc) {
      push('/?tab=upcoming');
    }
  };

  const close = async () => {
    await dispatch(closeModal());
    if (scheduleId) {
      push('/?tab=open');
    }
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={close}
      className="p-8 md:p-12 pt-12 h-auto w-auto md:w-eventCardWidth rounded-lg transform translate-y-24 md:translate-y-24 noshow-popup"
      shouldCloseOnOverlayClick={false}
      closebtnClass="close-btn-icon"
    >
      <div className="grid grid-cols-1 gap-y-0">
        <PrimaryLabel
          labelText={
            scheduleId
              ? EN.event.OPEN_MODAL.SCHEDULE_TITLE
              : EN.event.OPEN_MODAL.TITLE
          }
          className="sub-header col-span-2 text-InputFontCommon md:text-SubHeaderFont mb-6 h-auto font-brandonMedium"
        />
        <PrimaryLabel
          labelText={`${EN.event.OPEN_MODAL.SUB_TITLE}`}
          className="block col-span-2 text-InputFontCommon mb-2"
        />
        <div className="col-span-2 mt-6">
          <Button
            label={EN.button.YES_ASSIGNED_ME}
            className={`w-full md:w-auto md:w-32 block mb-4 md:mb-0 ${
              loading && 'pointer-events-none'
            }`}
            backgroundColor="#f36b7f"
            buttonText="#fefefe"
            type={ButtonType.BUTTON}
            onClick={onAssignOpenEvent}
          />
          <Button
            label={EN.button.NO_CANCEL}
            className={`w-full md:w-40 block px-simple-button md:mr-2 ml-0 md:ml-16 float-left`}
            type={ButtonType.BUTTON}
            onClick={close}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default SelfAssignPopup;
