import { IEventDateCount } from './../../models/event';
import {
  IEventDetail,
  Pagination,
  IViewNotesDetail,
  IEventOperationDetail,
  ImarkAttenDance,
  IBuildingList,
  ItimeZone,
} from '../../models';

export interface IEventState extends Pagination {
  eventCount: number;
  eventDetail: IEventDetail;
  eventDetailFetched: boolean;
  events: IEventDetail[];
  eventDateCount: IEventDateCount;
  viewNotesDetail: IViewNotesDetail;
  eventOperationalDetail: IEventOperationDetail;
  openChat: boolean;
  markUserAttenDance: ImarkAttenDance;
  buildingList: IBuildingList[];
  emailExist: boolean;
  timezones: ItimeZone[];
}

export const initialEventState: IEventState = {
  events: [],
  eventCount: 0,
  eventDateCount: {},
  eventDetailFetched: false,
  eventDetail: {
    displayName: '',
    startDatetime: '',
    endDatetime: '',
    id: null,
    virtual: false,
    location: '',
    building: '',
    vendorCut: 0,
    markNoShow: false,
  },
  viewNotesDetail: {
    adminNotes: '',
    wifi: '',
    security: {
      securityAccess: '',
      trunkCode: '',
    },
    info: {
      frontDeskNumber: '',
      streetAddress: '',
      bathroomAccess: '',
      studioLocation: '',
      videoUrl: '',
      water: '',
      garbageArea: '',
      outlets: '',
      floorPlan: '',
    },
  },
  eventOperationalDetail: {
    rsvps: 0,
    attended: 0,
    users: [],
    tasks: [],
    callList: [],
    inventory: [],
  },
  openChat: false,
  markUserAttenDance: {
    message: '',
  },
  buildingList: [],
  emailExist: false,
  timezones: [],
};
